<script setup>
import { storeToRefs } from "pinia";
import { useAiPracticeStore } from "@/stores/aiPracticeStore";
const aiPracticeStore = useAiPracticeStore();
const { AIPracticeDetail, AIPracticeDetailLoading } =
  storeToRefs(aiPracticeStore);
const { fetchAIPracticeDetail } = aiPracticeStore;

import { useRoute } from "vue-router";
const route = useRoute();
const query = route.query;

import { onMounted } from "vue";
onMounted(async () => {
  await fetchAIPracticeDetail(query.ai_practice_id);
});

// import CustomProgress from "@/components/CustomProgressBar.vue";
</script>
<template>
  <div class="main-content-container pa-3">
		<v-skeleton-loader v-if="AIPracticeDetailLoading" type="image, article" :loading="AIPracticeDetailLoading" ></v-skeleton-loader>
    <v-card class="container" v-if="AIPracticeDetail && !AIPracticeDetailLoading">
      <div >
        <p class="title mb-5">{{ AIPracticeDetail.title_en }}</p>
        <div class="result">
          <div class="d-flex align-center justify-center">
            <v-img
              v-if="AIPracticeDetail.image"
              :src="AIPracticeDetail.image.path"
              height="125"
              width="125"
            />
          </div>
          <div class="my-6 text" v-if="AIPracticeDetail.missions">
            <p class="mb-2">
              <v-icon color="#75F94C">mdi-check-circle</v-icon> Missions
            </p>
            <v-card width="180" variant="flat" color="transparent" class="mx-auto mt-5 py-2">
              <div
                v-for="(mission, index) in AIPracticeDetail.missions"
                :key="index"
                class="d-flex align-center mb-2"
              >
                <v-icon :color="mission.is_completed ? '#75F94C' :'grey'" class="mr-2">
                  {{
                    mission.is_completed
                      ? "mdi-check-circle-outline"
                      : "mdi-close-circle-outline"
                  }}
                </v-icon>
                <div>
                  <p class="text-left">{{ mission.title_ko }}</p>
                  <p class="text-caption text-left">{{ mission.title_en }}</p>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>

      <!-- <p class="header">Review</p> -->
      <!-- <div class="py-5 computation">
        <v-row dense align="center">
          <v-col cols="4" class="text-left"> Fluency </v-col>
          <v-col cols="8" class="mb-2">
            <CustomProgress :value="40" progressColor="#FBEFEF" />
          </v-col>
          <v-col cols="4" class="text-left"> Pronunciation </v-col>
          <v-col cols="8" class="mb-2">
            <CustomProgress :value="50" progressColor="#FBEFEF" />
          </v-col>
          <v-col cols="4" class="text-left"> Accuracy </v-col>
          <v-col cols="8">
            <CustomProgress :value="90" progressColor="#FBEFEF" />
          </v-col>
        </v-row>
      </div> -->
    </v-card>
    <br />
  </div>
</template>

<style scoped>
.container {
  background: #096fb8;
  border-radius: 24px;
  padding: 22px;
}
.title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  text-align: center;
  color: #ffffff;
}
.result {
  color: white;
  width: 100%;
  padding: 20px;
  border-radius: 24px;
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
}
.header {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: center;
  color: white;
  margin-top: 25px;
}
.computation {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
  color: white;
}
</style>
