import { defineStore } from 'pinia'
import * as api from '@/core/api'

import { ref } from 'vue'
export const useCategoryStore = defineStore('categoryStore', () => {
	const categoryList = ref([])
	const categoryListLoading = ref(false)
	const categoryQuery = ref({})
	const setCategoryFetchQuery = (query) => {
		categoryQuery.value = query
	}

	const fetchCategoryList = async () => {
		categoryListLoading.value = true
		let res = null
		await api
			.get('/categories', categoryQuery.value)
			.then((response) => {
				categoryList.value = response.data
				res = response
			})
			.finally(() => {
				categoryListLoading.value = false
			})
		return res
	}

	return {
		// Category List Page
		categoryList,
		categoryListLoading,
		categoryQuery,
		setCategoryFetchQuery,
		fetchCategoryList
	}
})