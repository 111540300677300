<template>
  <div class="d-flex">
    <v-btn variant="text" density="compact" icon>
      <v-img src="@/assets/community-like.svg" height="20" width="20" />
    </v-btn>
    <v-btn variant="text" density="compact"  icon>
      <v-img src="@/assets/community-heart.svg" height="20" width="20" />
    </v-btn>
    <v-btn variant="text" density="compact" icon>
      <v-img src="@/assets/community-bookmark.svg" height="20" width="20" />
    </v-btn>
  </div>
</template>
