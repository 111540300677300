<template>
  <div class="body-width page-bg">
    <CommonHeader />
    <router-view />
    <CommonFooter />
		<DialogConfirmation />
		<SnackBar />
  </div>
</template>
<script setup>
import CommonFooter from "@/components/CommonFooter.vue";
import CommonHeader from "@/components/CommonHeader.vue";
import { watch } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
watch(
  () => route,
  () => { setBodyBackground() },
  { deep: true }
);

const setBodyBackground = () => {
	const val = route;
  var bodyElement = document.querySelector(".body-width");
  if (bodyElement) {
    var bodyClass = bodyElement.classList;
    const customClass = ["page-bg", "bg-signup"];
    for (let i = 0; i < customClass.length; i++) {
      if (bodyClass.contains(customClass[i])) {
        bodyElement.classList.remove(customClass[i]);
      }
    }
    var defaultBg = "page-bg";
    if (val.meta && val.meta.bg) {
      bodyElement.classList.remove(defaultBg);
      defaultBg = val.meta.bg;
    }
    if (!bodyElement.classList.contains(defaultBg)) {
      bodyElement.classList.add(defaultBg);
    }
  }
};

// CONFIRMATION DIALOG
import DialogConfirmation from "@/components/Dialogs/DialogConfirmation.vue";

// SNACKBAR DIALOG
import SnackBar from "@/components/SnackBar.vue";
</script>
<style>
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"); */
/* import global css  */
@import "@/style/global.css";
</style>
