import { useSpeechSynthesis } from '@vueuse/core'
import { onMounted, ref } from 'vue'

export function useTTS () {
  const ttsVoice = ref(undefined)
  const ttsText = ref('')
  const ttsPitch = ref(1)
  const ttsRate = ref(0.7)

  const speech = useSpeechSynthesis(ttsText, {
    lang: 'ko-KR',
    voice: ttsVoice,
    pitch: ttsPitch,
    rate: ttsRate,
  })

  let synth = window.speechSynthesis

  const ttsVoices = ref([])

  onMounted(() => {
    if (speech.isSupported.value) {
      // load at last
      setTimeout(async () => {
        synth = window.speechSynthesis
        ttsVoices.value = synth.getVoices().filter(voice => voice.lang === 'ko-KR')
        ttsVoice.value = ttsVoices.value.find(item => item.name === 'Google 한국의') ?? ttsVoices.value[10] ?? ttsVoices.value[0]
      }, 10)
    }
  })

  async function play() {

    if (speech.status.value === 'pause') {
      console.log('TTS resume')
      window.speechSynthesis.resume()
    } else {
      speech.speak()
    }
  }

  async function pause() {
    window.speechSynthesis.pause()
  }

  async function stop() {
    speech.stop()
  }

  return {
    ttsVoice,
    ttsText,
    ttsPitch,
    ttsRate,

    play,
    pause,
    stop
  }
}
