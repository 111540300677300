<script setup>
import { onMounted, ref } from "vue";

// import TextFieldSearch from "@/components/TextFieldSearch.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import CardSubCategory from "@/components/Cards/CardSubCategory.vue";

import { useRoute } from "vue-router";
const route = useRoute();
const id = route.query.category_id;
const category = route.query.category;
const breadCrumbs = ref([route.query.category]);

// SUBCATEGORY STORE
import { useSubCategoryStore } from "@/stores/subCategoryStore";
import { storeToRefs } from "pinia";
const subCategoryStore = useSubCategoryStore();
const { subCategoryList, subCategoryListLoading } =
  storeToRefs(subCategoryStore);
const { fetchSubCategoryList, setSubCategoryQuery } = useSubCategoryStore();

onMounted(() => {
  subCategoryList.value = [];
  setSubCategoryQuery({
    category_id: id,
    with_image: true,
    for_sub_category_page: true,
    all: true,
  });

  fetchSubCategoryList();
});

</script>

<template>
  <div class="main-content-container">
    <BreadCrumb :items="breadCrumbs" />
    <NoDataFound v-if="!subCategoryListLoading && subCategoryList.length == 0"/>
    <v-row
      dense
      class="mt-4"
      v-if="subCategoryListLoading && subCategoryList.length == 0"
    >
      <v-col cols="6">
        <v-skeleton-loader class="rounded-lg" type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader class="rounded-lg" type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader class="rounded-lg" type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader class="rounded-lg" type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row dense class="mt-4" v-if="subCategoryList.length > 0">
      <v-col
        cols="6"
        v-for="(subCategory, index) in subCategoryList"
        :key="index"
      >
        <CardSubCategory
          :title="subCategory.name"
          :img="subCategory.image.path"
          style="cursor: pointer"
          @click="
            $router.push({
              name: 'ai-practice-list',
              query: {
                category: category,
                sub_category: subCategory.name,
                sub_category_id: subCategory.id,
              },
            })
          "
        />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.player {
  width: 100%;
  height: 163px;
  margin-top: 40px;
}
</style>
