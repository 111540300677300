<script setup>
import { onMounted, ref } from "vue";

// import TextFieldSearch from "@/components/TextFieldSearch.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import CardAIPractice from "@/components/Cards/CardAIPractice.vue";

import { useRoute } from "vue-router";
const route = useRoute();

const breadCrumbs = ref([route.query.category, route.query.sub_category]);

// SUBCATEGORY STORE
import { useAiPracticeStore } from "@/stores/aiPracticeStore";
import { storeToRefs } from "pinia";
const aiPracticeStore = useAiPracticeStore();
const { aiPracticeList, aiPracticeListLoading } = storeToRefs(aiPracticeStore);
const { fetchAIPracticeList, setAIPracticeQuery } = useAiPracticeStore();

onMounted(() => {
  aiPracticeList.value = [];
  setAIPracticeQuery({
    with_image: true,
    for_ai_practice_list_page: true,
    sub_category_id: route.query.sub_category_id,
    all: true,
  });

  fetchAIPracticeList();
});
</script>

<template>
  <div class="main-content-container">
    <BreadCrumb :items="breadCrumbs" />
    <no-data-found
      v-if="!aiPracticeListLoading && aiPracticeList.length == 0"
    />
    <v-row
      dense
      class="mt-4"
      v-if="aiPracticeListLoading && aiPracticeList.length == 0"
    >
      <v-col cols="6">
        <v-skeleton-loader class="rounded-lg" type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader class="rounded-lg" type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader class="rounded-lg" type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader class="rounded-lg" type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row dense class="mt-4" v-if="aiPracticeList.length > 0">
      <v-col
        cols="6"
        v-for="(ai_practice, index) in aiPracticeList"
        :key="index"
      >
        <CardAIPractice
          :title="ai_practice.title_en"
          :img="ai_practice?.image?.path"
          style="cursor: pointer"
          @click="
            $router.push({
              name: 'ai-practice',
              query: { id: ai_practice.id },
            })
          "
        />
      </v-col>
    </v-row>
  </div>
</template>
