import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import * as api from "@/core/api";
import { useOpenAIStore } from "@/stores/openAIStore";
import { parse } from "best-effort-json-parser";

// USER PROGRESS STORE
import { useUserProgressStore } from "@/stores/userProgressStore";
import { useVoiceInputStore } from "@/stores/voiceInputStore";
export const useAiPracticeStore = defineStore(
  "aiPracticeStore",
  () => {
    const aiPracticeList = ref([]);
    const aiPracticeListLoading = ref(false);
    const aiPracticeListQuery = ref({});

    const setAIPracticeQuery = (query) => {
      aiPracticeListQuery.value = query;
    };

    const fetchAIPracticeList = async () => {
      aiPracticeListLoading.value = true;
      let res = null;
      await api
        .get("/ai-conversations", aiPracticeListQuery.value)
        .then((response) => {
          aiPracticeList.value = response.data;
          res = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          aiPracticeListLoading.value = false;
        });
      return res;
    };

    const AIPracticeDetail = ref({});
    const AIPracticeDetailLoading = ref(false);
    const fetchAIPracticeDetail = async (id = null) => {
      if (!id) return;
      AIPracticeDetail.value = null;
      AIPracticeDetailLoading.value = true;
      let res = null;
      await api
        .get(`/ai-conversations/${id}`)
        .then((response) => {
          AIPracticeDetail.value = response.data;
          res = response.data;
          console.log(res);
        })
        .finally(() => {
          AIPracticeDetailLoading.value = false;
        });
      return res;
    };

    // AI CONVERSATION VIDEO LESSON PAGE
    const aiPracticeVideoLesson = ref({});
    const aiPracticeVideoLessonLoading = ref(false);
    const ActiveVideoLesson = ref({});
    const fetchAIPracticeVideoLesson = async (ai_conversation_id) => {
      aiPracticeVideoLesson.value = {};
      aiPracticeVideoLessonLoading.value = true;
      ActiveVideoLesson.value = {};
      let res = null;
      await api
        .get(`/ai-conversations`, {
          ai_conversation_id: ai_conversation_id,
          with_videos: true,
          single: true,
          for_video_lesson: true,
          with_videos_image: true,
          with_videos_words: true,
          with_videos_words_image: true,
        })
        .then((response) => {
          aiPracticeVideoLesson.value = response.data;
          ActiveVideoLesson.value = response.data.videos[0];
          res = response.data;
        })
        .finally(() => {
          aiPracticeVideoLessonLoading.value = false;
        });
      return res;
    };

    // AI CONVERSATION FOR MISSIONS
    const { createOpenAIThread, initializeOpenAI, AITextToSpeech } =
      useOpenAIStore();
    const InitilizeAI = async () => {
      await initializeOpenAI();
      await createOpenAIThread();
    };

    const AIConversationMissionIndex = ref(0);
    const AIConversationMission = ref([]);
    const CurrentMission = ref(null);
    const endOfMission = ref(false);
    const handleAddStaticeConvo = async (mission_index = 0) => {
      if (!AIConversationMission.value[mission_index]) {
        endOfMission.value = true;
        return;
      }
      endOfMission.value = false;
      AIConversationMissionIndex.value = mission_index;
      CurrentMission.value = AIConversationMission.value[mission_index];
    };

    const openAI = useOpenAIStore();
    const { openai, thread } = storeToRefs(openAI);
    const aiLoadingMessage = ref(false);
    const { handleVoiceClick } = useVoiceInputStore();
    const handleUserMessage = async (message) => {
      if (!openai.value) await InitilizeAI();
      if (!thread.value) await createOpenAIThread();
      if (!message) return;
      if (aiLoadingMessage.value) return;
      handleConversationHistory(message, "user");
      aiLoadingMessage.value = true;
      await openai.value.beta.threads.messages.create(thread.value.id, {
        role: "user",
        content: message,
      });
      var res = null;
      try {
        const stream = await openai.value.beta.threads.runs.create(
          thread.value.id,
          {
            assistant_id: AIPracticeDetail.value.assistant_key,
            stream: true,
          }
        );
        for await (const chunk of stream) {
          if (chunk.event == "thread.message.completed") {
            var value = chunk.data?.content?.[0]?.text?.value;
            if (value.includes("```json")) {
              value = value.replace("```json", "").replace("```", "");
            }
            var isJson = false;

            // first check for json
            try {
              JSON.parse(value);
              isJson = true;
            } catch (e) {
              isJson = false;
            }
            if (value.length > 10 && isJson) {
              const json = parse(value);
              res = json;
            } else {
              try {
                JSON.parse(value);
                isJson = true;
              } catch (e) {
                isJson = false;
              }
              if (isJson) res = JSON.parse(value);
              else res = { message: value };
            }
          }
        }
        if (res) {
          handleConversationHistory(res.message, "ai");
          await AITextToSpeech(res.message).then(() => {
            isMissionAnswered(res);
            handleVoiceClick();
          });
        }
        aiLoadingMessage.value = false;
      } catch (error) {
        console.error(error, "hereCatch Conversation Error");
        aiLoadingMessage.value = false;
      }
    };
    const { handleUserProgress } = useUserProgressStore();
    const isMissionAnswered = (res) => {
      if (res.mission_id && res.completed) {
        // handle user progress add completed to mission
        AIPracticeDetail.value.missions.map((mission) => {
          if (mission.id == res.mission_id) {
            mission.is_completed = true;
          }
          return mission;
        });
        handleUserProgress({ id: res.mission_id, status: "completed" });
      }
    };

    const conversationHistory = ref([]);
    const handleConversationHistory = (message, type = "user") => {
      conversationHistory.value.push({
        type: type,
        message: message,
      });
      scrollDownConversationHistory();
    };

    const scrollDownConversationHistory = () => {
      const chatContainer = document.querySelector(".messageBody");
      if (chatContainer) chatContainer.scrollTop = chatContainer.scrollHeight;
    };

    return {
      // LIST PAGE
      aiPracticeList,
      aiPracticeListLoading,
      aiPracticeListQuery,
      setAIPracticeQuery,
      fetchAIPracticeList,

      // AI CONVERSATION DETAIL PAGE
      AIPracticeDetail,
      AIPracticeDetailLoading,
      aiLoadingMessage,
      fetchAIPracticeDetail,

      // AI CONVERSATION VIDEO LESSON PAGE
      aiPracticeVideoLesson,
      aiPracticeVideoLessonLoading,
      ActiveVideoLesson,
      AIConversationMission,
      AIConversationMissionIndex,
      CurrentMission,
      endOfMission,
      fetchAIPracticeVideoLesson,
      InitilizeAI,
      handleAddStaticeConvo,
      handleUserMessage,
      isMissionAnswered,

      // CONVERSATION HISTORY
      conversationHistory,
      handleConversationHistory,
    };
  },
  {}
);
