<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import BreadCrumb from "@/components/BreadCrumb.vue";
import YoutubePlayer from "@/components/Others/YoutubePlayer.vue";

import { useRoute } from "vue-router";
const route = useRoute();
const id = route.query.id ? route.query.id : 1;
const playerHigth = ref(350);

const setYoutubeHight = () => {
  if (window.innerWidth < 800) playerHigth.value = 300;
  else playerHigth.value = 330;
};
setYoutubeHight();

// VIDEO DETAIL
import { useVideoStore } from "@/stores/videoStore";
const videoStore = useVideoStore();
const { videoDetail, videoDetailLoading } = storeToRefs(videoStore);
const { fetchVideoDetail } = useVideoStore();

fetchVideoDetail(id);

// WORD CARD
import CardWord from "@/components/Cards/CardWord.vue";
</script>

<template>
  <div class="main-content-container">
    
    <div v-if="videoDetail">
			<BreadCrumb v-if="videoDetail.category" :items="[videoDetail.category?.name, videoDetail.sub_category?.name ]" lass="pl-2" />
      <p class="title text-left mb-1 mt-4">
        {{ videoDetail.title_en }}
      </p>
      <div class="player" v-if="videoDetail.youtube_link"> 
        <YoutubePlayer iframeClass="i-frame-test" :id="videoDetail.youtube_link" />
      </div>
      <div v-if="videoDetail.words && videoDetail.words.length > 0">
        <p class="text-left title mb-2 pb-0 mt-4">[New Words]</p>
        <v-row dense class="mb-2">
          <v-col
            cols="4"
            v-for="(word, index) in videoDetail.words"
            :key="index"
          >
            <CardWord
              :word_en="word.word_en"
              :word_ko="word.word_ko"
              :img="word.image.path"
            />
          </v-col>
        </v-row>
        <!-- <p class="hashtag text-left mb-0 pb-0">#best_Korean_soju_brands</p> -->
        <!-- <p class="hashtag text-left mb-1">#Korean_Beer</p> -->
      </div>
    </div>
    <v-skeleton-loader v-if="videoDetailLoading" type="heading,image, article"></v-skeleton-loader>
  </div>
</template>
<style scoped>
.title {
  font-family: "SOYO Maple Bold";
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.hashtag {
  font-family: "SOYO Maple Bold";
  font-size: 14px;
  font-weight: bold;
  line-height: 14.52px;
}
</style>
