
<template>
  <div class="main-content-container text-left pt-4 px-5">
    <div >
      <SelectPlan v-if="step == 1" @plan="selectPlan" />
			<BillingDetail v-if="step == 2" @billing="setBilling" />
			<SubscriptionDetail v-if="step == 3" @subscription="setSubscription"  />
			<ReviewAndPayment v-if="step == 4" @makePayment="setMakePayment" />
    </div>
  </div>
</template>

<script setup>
import SelectPlan from "@/components/Subscription/SelectPlan.vue";
import BillingDetail from "@/components/Subscription/BillingDetail.vue";
import SubscriptionDetail from "@/components/Subscription/SubscriptionDetail.vue";
import ReviewAndPayment from "@/components/Subscription/ReviewAndPayment.vue";

import { ref } from "vue";
const step = ref(1);

// ignore eslint 
// eslint-disable-next-line no-unused-vars
const data = ref({
	plan : null,
	billing_details : {
		name : '',
		email : '',
		address : '',
		city : '',
		state : '',
		zip : '',
		country : ''
	},
	subscription : {
		plan : null,
		period : null,
		amount : null,
		promocode : null,
	},
	payment : {
		card_number : '',
		expiry_date : '',
		cvc : ''
	}
})

const selectPlan = (plan = 'monthly') => {
	data.value.plan = plan;
	step.value = 2;
};

const setBilling = (billing = {}) => {
	data.value.billing_details = billing;
	step.value = 3;
};

const setSubscription = (subscription = {}) => {
	data.value.subscription = subscription;
	step.value = 4;
};

const setMakePayment = (payment) => {
	data.value.payment = payment;
	step.value = 1;
};
</script>
