<script setup>
import { storeToRefs } from "pinia";
import CardVideo from "@/components/Cards/CardVideo.vue";
import CardBookmarked from "@/components/Cards/CardBookmarked.vue";
// import CardItemSmall from "@/components/Cards/CardItemSmall.vue";

// user Store
import { useUserStore } from "@/stores/userStore";
const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);

// AIPractice Store
import { useAiPracticeStore } from "@/stores/aiPracticeStore";
const aiPracticeStore = useAiPracticeStore();
const { aiPracticeList, aiPracticeListLoading } = storeToRefs(aiPracticeStore);
const { fetchAIPracticeList, setAIPracticeQuery } = useAiPracticeStore();

aiPracticeList.value = [];
setAIPracticeQuery({
  limit: 4,
  order_by: "created_at",
  order: "desc",
  all: true,
  with_image: true,
  with_user: true,
  with_sub_category: true,
  is_bookmarked: true,
  for_client_home_page: true,
});

fetchAIPracticeList();

// Video Store
import { useVideoStore } from "@/stores/videoStore";
const videoStore = useVideoStore();
const { videoList, videoListLoading } = storeToRefs(videoStore);
const { fetchVideoList, setVideoListQuery } = useVideoStore();
setVideoListQuery({
  limit: 2,
  order_by: "created_at",
  order: "desc",
  all: true,
  with_image: true,
  with_category: true,
  is_bookmarked: true,
  for_client_home_page: true,
});
fetchVideoList();

// Post Store
import CardPost from "@/components/Cards/CardPost.vue";
import { useCommunityStore } from "@/stores/communityStore";
const { communityList, communityListLoading } = storeToRefs(
  useCommunityStore()
);
const { fetchCommunityList, setFetchQuery } = useCommunityStore();

setFetchQuery({
  limit: 2,
  order_by: "created_at",
  order: "desc",
  all: true,
  user_id: currentUser.id,
  is_bookmarked: true,
  with_images: true,
  with_user: true,
  for_client_home_page: true,
});
fetchCommunityList();

// Bookmarks Store
import { useBookmarkStore } from "@/stores/bookmarkStore";
const bookmarkStore = useBookmarkStore();
const { bookMarkList, bookmarkListLoading } = storeToRefs(bookmarkStore);
const { fetchBookmarkList, setBookmarkQuery } = useBookmarkStore();

setBookmarkQuery({
  limit: 2,
  order_by: "created_at",
  order: "desc",
  all: true,
  user_id: currentUser.id,
  with_images: true,
  bookmarkable_type: "posts",
  for_profile_page: true,
});

fetchBookmarkList();
</script>
<template>
  <div class="main-content-container mb-4" v-if="currentUser">
    <div class="person d-flex align-center justify-space-between px-4 mb-4">
      <div>
        <v-avatar>
          <v-img
            class="cursor-pointer"
            @click="$router.push({ name: 'profile' })"
            width="40"
            height="40"
            :src="
              currentUser?.image?.path ||
              require('@/assets/header-cat-icon.svg')
            "
          />
        </v-avatar>
      </div>
      <div class="d-flex align-center">
        <div class="px-3">{{ currentUser.name }}</div>
        <div class="px-3">{{ currentUser.email }}</div>
        <!-- <div class="px-3">자민</div> -->
      </div>
    </div>
    <p class="title mb-2">My Status</p>
    <div class="status d-flex align-center justify-space-between px-4 mb-4">
      <div>
        <v-img height="70" width="64" src="@/assets/profile-chick.svg" />
      </div>
      <div class="status-progress d-flex align-center">
        <div class="mr-3">
          <p class="status-title">Lessons</p>
          <div class="blob-container">
            <div class="green d-flex align-center justify-center">20</div>
            <span class="grey d-flex align-center justify-center"> 30 </span>
          </div>
        </div>
        <div>
          <p class="status-title">Practice</p>
          <div class="blob-container">
            <div class="green d-flex align-center justify-center">7</div>
            <span class="grey d-flex align-center justify-center"> 10 </span>
          </div>
        </div>
      </div>
    </div>
    <p class="title mb-2">My Study</p>
    <v-row v-if="videoListLoading && videoList.length == 0">
      <v-col cols="6" v-for="n in 2" :key="n">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row dense class="mb-4" v-if="videoList && videoList.length > 0">
      <v-col cols="6" v-for="(video, index) in videoList" :key="index">
        <CardVideo
          :title="video.category?.name"
          :contentTitle="video?.title_kor"
          :contentImg="video.image?.path"
          :video="video"
        />
      </v-col>
    </v-row>
    <p class="title mb-2">My Practices</p>
    <v-row v-if="aiPracticeListLoading && aiPracticeList.length == 0">
      <v-col cols="3" v-for="n in 4" :key="n">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row dense class="mb-4" v-if="aiPracticeList.length > 0">
      <v-col cols="3" v-for="(practice, index) in aiPracticeList" :key="index">
        <CardItemSmall
          :title="practice.title_en"
          :img="practice.image?.path"
          :practice="practice"
        />
      </v-col>
    </v-row>
    <p class="title mb-2">My Post</p>
    <v-row v-if="communityListLoading && communityList.length == 0">
      <v-col cols="6" v-for="n in 2" :key="n">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row dense class="mb-5" v-if="communityList.length > 0">
      <v-col cols="6" v-for="(post, index) in communityList" :key="index">
        <CardPost
          :title="post.title"
          :subtitle="post.description"
          :userName="post.user?.name"
          :img="post.images && post.images[0] ? post.images[0].path : null"
          :post="post"
        />
      </v-col>
    </v-row>
    <p class="title mb-2">Bookmarks</p>
    <v-row v-if="bookmarkListLoading && bookMarkList.length == 0">
      <v-col cols="6" v-for="n in 2" :key="n">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row dense class="mb-5" v-if="bookMarkList.length > 0">
      <v-col cols="6" v-for="(bookmark, index) in bookMarkList" :key="index">
        <CardBookmarked
          :title="bookmark.bookmarkable?.title"
          :subtitle="bookmark.bookmarkable?.description"
          :userName="bookmark.bookmarkable?.user?.name"
          :img="
            bookmark.bookmarkable?.images && bookmark.bookmarkable.images[0]
              ? bookmark.bookmarkable.images[0].path
              : null
          "
          :bookmark="bookmark"
        />
      </v-col>
    </v-row>
    <br />
  </div>
</template>

<style scoped>
.main-content-container {
  height: 100%;
  min-height: 100vh;
}
.person {
  height: 64px;
  border-radius: 26px;
  background: #096fb8;
  color: white;
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
}

.status {
  height: 96px;
  border-radius: 26px;
  background: #096fb8;
  color: white;
}
.status-title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
}
.title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  color: #ff528a;
}
.blob-container {
  width: 90px;
  height: 60px;
  position: relative;
  font-size: 25px;
}
.blob-container .green {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 50px;

  background: #56b48b;
  border-radius: 30px;
  z-index: 2;
}
.blob-container .grey {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  background: #a6a6a6;
  border-radius: 30px;
  z-index: 1;
}
.status-progress {
  /* min-width: 200px; */
}
</style>
