<style scoped>
.login-bg {
  height: 100vh;
}
.title {
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  color: white;
  margin-bottom: 20px;
}
.btn-login {
  border: 2px solid #ffffff;
  background: transparent;
  color: white;
}
.btn-register {
  background: #ff528a;
  color: white;
}
</style>
<template>
  <div class="login-bg d-flex align-center justify-center">
    <div class="">
      <p class="title">Welcome</p>
      <div class="text-left mb-3 pl-5 ml-5">
        <v-img
          class="ml-3"
          width="150"
          height="96"
          src="../assets/login-kokotalk-icon.svg"
        />
        <!-- <v-img width="96" height="96" src="../assets/login-trash-icon.svg" /> -->
      </div>
      <br />
      <br />
      <br />
      <div class="d-flex align-center flex-column">
        <v-btn
          class="d-block elevation-0 mb-4 action-button btn-login"
          @click="$router.push({ name: 'login' });"
        >
          LOGIN
        </v-btn>
        <v-btn
          class="d-block elevation-0 action-button btn-register"
          @click="$router.push({ name: 'signup' })"
        >
          Create an Account
        </v-btn>
      </div>
    </div>
  </div>
</template>