<script setup>
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
const route = useRoute();
const query = route.query || {};

// Persistent Store
import { usePersistantStore } from "@/stores/persistentStore";
const persistentStore = usePersistantStore();
const { selected_subscription_id } = storeToRefs(persistentStore);

// Payment Store
import { usePaymentStore } from "@/stores/paymentStore";
const paymentStore = usePaymentStore();
const { paymentLoading } = storeToRefs(paymentStore);
const { handlePayment } = usePaymentStore();

// USER STORE
import { useUserStore } from "@/stores/userStore";
import { onMounted } from "vue";
const userStore = useUserStore();
const USER = userStore.getCurrentUser;

onMounted(() => {
  if (query && query.tid) {
    var form = {
      subscription_id: selected_subscription_id.value,
      user_id: USER?.id,
      ...query,
    };
    handlePayment(form);
  }
});
</script>

<template>
  <div class="main-content-container text-center pt-4 px-5">
    <div v-if="paymentLoading">
      <v-progress-circular size="120" indeterminate
        >Please Wait</v-progress-circular
      >
    </div>
    <v-card class="py-6" variant="flat" v-if="!paymentLoading">
      <br />
      <v-icon size="100" color="success" class=""
        >mdi-check-circle-outline</v-icon
      >
      <h1 class="title mb-4">Payment Successful</h1>
      <p class="description">
        Your payment has been successfully processed. You will receive an email
        confirmation shortly.
      </p>
      <br />
      <br />
      <v-btn
        @click="$router.push({ name: 'home' })"
        color="#ff528a"
        width="150"
        class="rounded-pill"
        height="50"
        >Continue</v-btn
      >
    </v-card>
  </div>
</template>
