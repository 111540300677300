<script setup>
import { defineProps, ref, watch } from "vue";
import { storeToRefs } from "pinia";
const props = defineProps({
  dialog: {
    type: Boolean,
    default: false,
  },
});
const show = ref(false);
watch(
  () => props.dialog,
  (val) => {
    show.value = val;
  },
  { immediate: true }
);

// AI Practice  Store
import { useAiPracticeStore } from "@/stores/aiPracticeStore";
const aiPracticeStore = useAiPracticeStore();
const { AIPracticeDetail } = storeToRefs(aiPracticeStore);
</script>
<template>
  <v-dialog persistent v-model="show" width="250">
    <v-card color="#E9FCFF">
      <v-card-title class="text-left align-center justify-space-between d-flex">
        <div class="d-flex align-center">
          <v-icon color="#FFFD54" size="24" class="mr-2"
            >mdi-lightbulb-on</v-icon
          >
          <p>Hints</p>
        </div>
        <v-btn icon size="26" variant="text">
          <template v-slot:default>
            <v-icon size="24" @click="$emit('dialog', false)">mdi-close</v-icon>
          </template>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="text-left"
        v-if="AIPracticeDetail && AIPracticeDetail.hint"
      >
        {{ AIPracticeDetail.hint }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
