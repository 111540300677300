<style scoped>
.override {
  position: fixed;
  height: 100%;
  width: 100%;
	left: 0;
	top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: start;
  justify-content: end;
  text-align: right;
  z-index: 9999;
}
.btn {
  border-radius: 20px 0 0 0;
}
.list {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: center;
	margin-left: auto;
}
.show {
  animation: slide-in 0.5s forwards;
  display: flex;
}
.constraint {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  height: 100%;
}
.content{
	min-width: 200px; 
	max-width: 375px; 
	width: 100%;
	border-top-right-radius: 0;
}
</style>
<template>
  <div :class="`override ${show ? 'show' : ''}`">
    <div class="constraint">
      <div class="d-flex flex-column align-end">
        <v-btn class="btn" height="64" width="64">
          <template v-slot:default>
            <v-icon size="50" @click="$emit('dialog', false)">mdi-close</v-icon>
          </template>
        </v-btn>
        <v-card  class="content">
          <v-list class="text-center list">
            <v-list-item link @click="changeRoute('home')">
              <v-list-item-title>Homepage</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="changeRoute('profile')">
              <v-list-item-title>My Profile</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="changeRoute('subscription')">
              <v-list-item-title>Subscription</v-list-item-title>
            </v-list-item>
						<v-list-item link @click="changeRoute('payment-list')">
              <v-list-item-title>Payments</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="changeRoute('setting')">
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="
                emit('logout');
                emit('dialog', false);
              "
            >
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, watch, defineEmits } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  dialog: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["dialog", "logout"]);
const show = ref(false);
watch(
  () => props.dialog,
  (val) => {
    show.value = val;
  },
  { immediate: true }
);

const changeRoute = (route) => {
  emit("dialog", false);
  router.push({ name: route });
};
</script>
