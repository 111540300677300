<style scoped>
.container {
  height: 190px;
  border-radius: 26px 0px 26px 26px;
  padding: 10px;
  position: relative;
	
}
.title {
  font-family: "SOYO Maple Bold";
  font-size: 15px;
	width: 140px;
	margin: 0 auto;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
	color: white;;
}
.img {
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
}
</style>
<template>
  <v-card variant="flat" :disabled="disabled">
		<div class="d-flex align-center justify-end">
			<v-icon v-for="index in startCount" color="yellow" :key="index">mdi-star</v-icon>
		</div>
    <div
      class="container"
      :style="`background : ${bgColor}; border : 1px solid ${borderColor}`"
    >
      <p class="title" v-html="title"></p>
      <v-img
        class="img"
        :height="imgHeight"
        :width="imgWidth"
        contain
        :src="img"
      />
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
		disabled : {
			type : Boolean,
			default : false
		},
    bgColor: {
      type: String,
      default: "#096FB8",
    },
    borderColor: {
      type: String,
      default: "#59DFF7",
    },
    title: {
      type: String,
      default: "Entrance of Kyoungbok Palace ",
    },
    img: {
      type: String,
      default: "chat-castle-icon.svg",
    },
    imgHeight: {
      type: String,
      default: "110",
    },
    imgWidth: {
      type: String,
      default: "150",
    },
		startCount : {
			type : Number,
			default : 3
		}
  },
};
</script>
