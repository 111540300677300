<script setup>
import { storeToRefs } from "pinia";
import { useCommunityStore } from "@/stores/communityStore";
const communtyStore = useCommunityStore();
const {
  showCommunityFormDialog,
  refPostDialog,
  communityForm,
  rules,
	postFormLoading,
  refInputFileImage,
  imageDisplayFiles,
} = storeToRefs(communtyStore);
const {
  closePostDialog,
  handlePostSubmit,
  handleImageFiles,
  handleAddImge,
  handleToDeleteImage,
} = useCommunityStore();
</script>

<template>
  <v-dialog
    lazy-validation
    width="500"
    persistent
    v-model="showCommunityFormDialog"
  >
    <v-form
      ref="refPostDialog"
      lazy-validation
      @submit.prevent="handlePostSubmit"
    >
      <v-card :loading="postFormLoading" :disabled="postFormLoading" rounded="xl" color="#F1E1C2" class="pa-2 pt-4">
        <p class="title">Create Post</p>
        <v-card-text class="px-2">
          <v-row class="mx-0">
            <v-col cols="12">
              <v-text-field
                label="Title"
                bg-color="white"
                variant="solo"
                flat
                :rules="rules.title"
                v-model="communityForm.title"
                class="elevation-0"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Description"
                variant="solo"
                flat
                :rules="rules.description"
                v-model="communityForm.description"
                class="elevation-0"
              >
              </v-textarea>
            </v-col>
            <v-col cols="12" class="py-0">
              <input
                ref="refInputFileImage"
                type="file"
                multiple
                style="display: none"
                accept="image/*"
                @change.prevent="handleImageFiles"
              />
              <div class="d-flex align-center justify-space-between">
                <div>
                  <v-btn
                    @click="handleAddImge"
                    variant="text"
                    type="button"
                    class="mr-1"
                    density="comfortable"
                    icon="mdi-image"
                  >
                  </v-btn>
                  <!-- <v-btn
                    variant="text"
                    density="comfortable"
                    icon="mdi-paperclip"
                  >
                  </v-btn> -->
                </div>
                <!-- <v-btn
                  variant="text"
                  density="comfortable"
                  icon="mdi-emoticon-happy-outline"
                >
                </v-btn> -->
              </div>
              <div
                v-if="imageDisplayFiles.length > 0"
                class="text-left d-flex flex-wrap"
              >
                <div v-for="(img, index) in imageDisplayFiles" :key="index">
                  <v-img
                    cover
                    height="100"
                    width="100"
                    class="rounded-lg elevation-3 mr-2 mb-2"
                    :src="img.url"
                  >
                    <div class="text-right pa-2">
                      <v-btn
                        @click="handleToDeleteImage(img)"
                        density="comfortable"
                        color="error"
                        size="small"
                        class="elevation-5"
                        icon="mdi-delete-outline"
                      />
                    </div>
                  </v-img>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            rounded="lg"
            color="#979595"
            class="mr-2"
            type="button"
            @click="closePostDialog"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="#7E8AF4"
            type="submit"
            variant="flat"
            width="100"
            rounded="lg"
            >Publish</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<style scoped>
.title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: center;
}
</style>
