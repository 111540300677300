<script setup>
import { storeToRefs } from "pinia";
import { ref, onMounted, onUnmounted } from "vue";
import * as api from "@/core/api";
import { useUserStore } from "@/stores/userStore";

const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);
const { updateCurrentUser } = useUserStore();

const userForm = ref({
  id: null,
  name: null,
  email: null,
  password: null,
  confirm_password: null,
  role: null,
  interest_id: null,
  skip_password: true,
});

const imageFile = ref(null);
const imageDisplayFile = ref({ main: null });
const refsImage = ref(null);

const populateUserForm = (data = {}) => {
  userForm.value.id = data.id || null;
  userForm.value.name = data.name || null;
  userForm.value.email = data.email || null;
  imageFile.value = null;

  if (data.image && data.image.path) {
    imageDisplayFile.value.main = data.image.path;
  } else {
    imageDisplayFile.value.main = null;
  }
};

const handleSelectImage = () => {
  refsImage.value.click();
};

const processImageFile = (file, variableName = "main") => {
  if (!file) {
    imageDisplayFile.value[variableName] = null;
    return;
  }
  const url = URL.createObjectURL(file);
  imageDisplayFile.value[variableName] = url;
};

const handleImage = (event, type = "main") => {
  const file = event.target?.files[0] || null;
  if (!file) {
    processImageFile(null, type);
  } else {
    processImageFile(file, type);
  }

  if (type === "main") {
    imageFile.value = file;
  }
};

onUnmounted(() => {
  if (imageFile.value) {
    URL.revokeObjectURL(imageFile.value);
  }
});

const handleUserSubmit = async () => {
  console.log("Form data before submission:", {
    id: userForm.value.id,
    name: userForm.value.name,
    email: userForm.value.email,
    skip_password: userForm.value.skip_password,
    imageFile: imageFile.value,
  });

  let URL = "/users";
  const formData = new FormData();

  if (userForm.value.id) {
    URL = `/users/${userForm.value.id}`;
    formData.append("_method", "PUT");
    formData.append("id", userForm.value.id);
  }

  formData.append("name", userForm.value.name);
  formData.append("email", userForm.value.email);
  formData.append("skip_password", userForm.value.skip_password);

  if (imageFile.value) {
    formData.append("image", imageFile.value);
  }

  for (const pair of formData.entries()) {
    console.log(`${pair[0]}: ${pair[1]}`);
  }

  try {
    const response = await api.custom({
      method: "POST",
      url: URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("Submission successful:", response);
    const updatedUser = response.data.data;
    console.log("updated user: ", updatedUser);

    updateCurrentUser({
      ...currentUser.value,
      name: updatedUser.name,
      email: updatedUser.email,
      image: updatedUser.image,
    });

    populateUserForm(updatedUser);
    imageDisplayFile.value.main = updatedUser.image?.path || null;
  } catch (error) {
    console.error("Submission error:", error);
  }
};

onMounted(() => {
  if (currentUser.value) {
    userForm.value = {
      id: currentUser.value.id,
      name: currentUser.value.name,
      email: currentUser.value.email,
      password: null,
      confirm_password: null,
      interest_id: null,
      skip_password: true,
    };

    if (currentUser.value.image && currentUser.value.image.path) {
      imageDisplayFile.value.main = currentUser.value.image.path;
    } else {
      imageDisplayFile.value.main = null;
    }
  }
});
</script>



<template>
  <div class="main-content-container text-left pt-4 px-5">
    <p class="title mb-2 pl-2">Edit Profile</p>
    <hr />
    <v-form ref="refUser" @submit.prevent="handleUserSubmit" class="my-4">
      <div class="d-flex py-2 align-center">
        <v-avatar
          size="98"
          :image="imageDisplayFile.main || null"
          class="elevation-3"
        >
        </v-avatar>
        <div class="ml-3">
          <p class="profile-text ml-4">Profile Picture</p>
          <input
            type="file"
            ref="refsImage"
            @change="handleImage($event, 'main')"
            accept="image/*"
            hidden
          />
          <v-btn @click="handleSelectImage" icon variant="text">
            <v-icon class="mt-1"> mdi-image-search-outline </v-icon>
          </v-btn>
        </div>
      </div>
      <v-row>
        <v-col cols="12">
          <v-text-field
            density="compact"
            hide-details
            label=""
            bg-color="white"
            rounded="lg"
            variant="outlined"
            flat
            v-model="userForm.name"
            class="elevation-0 input"
            placeholder="Full name "
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            density="compact"
            hide-details
            label=""
            type="email"
            bg-color="white"
            rounded="lg"
            variant="outlined"
            flat
            v-model="userForm.email"
            class="elevation-0 input"
            placeholder="Email"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <div class="text-right mt-4">
        <v-btn type="submit" class="rounded-lg elevation-0" color="#9F9CEE">
          Save
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
 
 <style scoped>
.title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: left;
}
.profile-text {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
}
</style>
