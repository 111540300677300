import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useUserStore = defineStore(
  "userStore",
  () => {
    /**
     * state
     */
    const users = ref([]);

    const currentUser = ref();

    const updateCurrentUser = (updatedUser) => {
      currentUser.value = { ...currentUser.value, ...updatedUser };
    };

    /**
     * getters
     */

    const getUsers = computed(() => {
      return users.value;
    });

    const getCurrentUser = computed(() => {
      return currentUser.value;
    });

    /**
     * actions
     */

    // add new user for mock data
    const addUser = (user) => {
      // generate id for new user
      user.id = users.value.length + 1;
      users.value.push(user);
    };

    // get user by id for mock data
    const getUserById = (id) => {
      return users.value.find((user) => user.id === id);
    };

    const setCurrentUser = (user) => {
      currentUser.value = user;
    };

    const setToken = (token) => {
      localStorage.setItem("token", token);
    };

    const clearToken = () => {
      localStorage.removeItem("token");
    };

    const unAuthenticate = () => {
      clearToken();
      setCurrentUser(null);
    };

    const userForm = ref({
      id: null,
      name: null,
      email: null,
      password: null,
      confirm_password: null,
      interest_id: null,
      image: null,
      skip_password: true,
    });

    const imageFile = ref([]);
    const imageDisplayFile = ref({});

    const populateUserForm = (data = {}) => {
      userForm.value.id = data.id || null;
      userForm.value.name = data.name || null;
      userForm.value.email = data.email || null;
      imageFile.value - null;

      if (data.image) {
        imageDisplayFile.value = data.image.path;
      } else {
        imageDisplayFile.value = null;
      }
    };

    const refUser = ref();

    return {
      users,
      currentUser,
      getUsers,
      getCurrentUser,
      addUser,
      getUserById,
      setCurrentUser,
      setToken,
      clearToken,
      unAuthenticate,
      userForm,
      populateUserForm,
      imageDisplayFile,
      refUser,
      updateCurrentUser,
    };
  },
  {
    persist: true,
  }
);
