<style scoped>
.container{
	max-width: 260px;
}
.coupon{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 125px;
	height: 100px;
	background-color: #FFC5C4;
	border-radius: 0px 24px 24px 0px;
	position: relative;
}
.percent{
	font-size: 40px;
	font-weight: bold;
	color : #FF6766;
}
.symbol{
	font-size: 40px;
	font-weight: bold;
	line-height: 24px;
	color : #FF6766;
}
.off{
	font-size: 20px;
	font-weight: bold;
	line-height: 24px;
	color : #FF6766;
}
p{
	font-size: 16px;
	line-height: 23px;
	font-weight: bold;
}
.small {
	font-size: 10px;
	line-height: 0px;
	font-weight: normal;
	color: #635C5C;
}
</style>

<template>
  <div class="text-left container">
    <p>
      [우리 집 삼겹날] <br />
      서울시 응난구 이태원로 15
    </p>
		<v-img class="my-1" src="@/assets/ai-cook.svg" height="115" width="170" />
		<div class="coupon flex-column mb-1">
			<div class="d-flex align-center justify-center">
			<div class="percent">10</div>
			<div class="d-flex align-center flex-column">
				<span class="symbol"> %  </span>
				<span class="off"> OFF </span>
			</div>
			</div>
			<p class="small pb-0 pt-0"> DISCOUNT COUPON </p>
		</div>
    <p>
      3년 숙성 묵은지 김치를 삼겹살과 같이 구워 먹는 곳으로 서울 삼겹살 맛집 추천합니다.
    </p>
  </div>
</template>
