<template>
  <v-text-field
    density="compact"
    rounded="lg"
    base-color="#9F9EED"
    small
    variant="outlined"
    hide-details
    height="20px"
    prepend-inner-icon="mdi-magnify"
  >
  </v-text-field>
</template>
