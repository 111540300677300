<script setup>
import { storeToRefs } from "pinia";
import CardCategory from "@/components/Cards/CardCategory.vue";

// ROUTER
import { useRoute } from "vue-router";
const route = useRoute();

import { useCategoryStore } from "@/stores/categoryStore";
import { onMounted } from "vue";
const categoryStore = useCategoryStore();
const { categoryList, categoryListLoading } = storeToRefs(categoryStore);
const { fetchCategoryList, setCategoryFetchQuery } = useCategoryStore();

onMounted(() => {
  categoryList.value = [];
  setCategoryFetchQuery({
    with_image: true,
    title: route.query.title ? route.query.title : null,
    for_category_page: true,
    all: true,
  });
  fetchCategoryList();
});
</script>

<style scoped>
.player {
  width: 100%;
  height: 163px;
  margin-top: 40px;
}
</style>
<template>
  <div class="main-content-container">
    <v-row
      dense
      class="mt-4"
      v-if="categoryListLoading && categoryList.length == 0"
    >
      <v-col cols="6">
        <v-skeleton-loader class="rounded-lg" type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader class="rounded-lg" type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader class="rounded-lg" type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader class="rounded-lg" type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row dense class="mt-4" v-if="categoryList.length > 0">
      <v-col cols="6" v-for="(category, index) in categoryList" :key="index">
        <CardCategory
          :title="category.name"
          :img="category.image.path"
          style="cursor: pointer"
          @click="
            $router.push({
              name: 'sub-category',
              query: { category_id: category.id, category: category.name },
            })
          "
        />
      </v-col>
    </v-row>
  </div>
</template>
