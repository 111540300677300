import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
// eslint-disable-next-line no-unused-vars

import { Buffer } from "buffer"; // Import the Buffer polyfill

// Assign Buffer to the global object
window.Buffer = Buffer;

const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);
app.use(vuetify);

// Global Components
import NoDataFound from "@/components/Others/NoDataFound.vue";
app.component("NoDataFound", NoDataFound);

app.mount("#app");
loadFonts();
