<style scoped>
	.container-progress{
		border : 2px solid #FFFFFF;
		height: 32px;
		border-radius: 24px;
		width: 100%;
		min-width: 200px;
		background: #096FB8;
	}
	.progress{
		height: 100%;
		border-radius: 24px; 
	}
</style>
<template>
	<div class="container-progress">
		<div class="progress" :style="`width:${value}%; background : ${progressColor};`"> </div>
	</div>
</template>
<script>
export default {
	props : {
		value : {
			type : Number,
			default : 50,
		},
		progressColor : {
			type : String,
			default : 'white',
		},
	},
	setup() {
		
	},
}
</script>
