import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";

// OPEN AI STORE
import { useOpenAIStore } from "@/stores/openAIStore";

export const useVoiceInputStore = defineStore("voiceInputStore", () => {
  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  const SpeechGrammarList =
    window.SpeechGrammarList || window.webkitSpeechGrammarList;

  const recognition = new SpeechRecognition();
  const speechRecognitionList = SpeechGrammarList ? new SpeechGrammarList() : null;

  if (speechRecognitionList) recognition.grammars = speechRecognitionList;
  recognition.continuous = true;
  recognition.lang = process.env.VUE_APP_INPUT_VOICE_LANG;
  recognition.interimResults = false;
  recognition.maxAlternatives = 1;

  const startRecording = ref(false);
  const voicePermission = ref(false);
  const microphoneColor = ref("secondary");
  const handleVoiceClick = async () => {
    if (!voicePermission.value) await getMicrophonePermission();
    if (!voicePermission.value) return;

    startRecording.value = !startRecording.value;
    if (startRecording.value) {
      startVoiceRecognition();
    } else {
      stopVoiceRecognition();
    }
  };
  const startVoiceRecognition = () => {
    microphoneColor.value = "error";
    recognition.start();
    console.log("Voice recognition has been started.");
  };

  const stopVoiceRecognition = () => {
    recognition.stop();
    microphoneColor.value = "primary";
    console.log("Voice recognition has been stopped.");
  };
  const openAI = useOpenAIStore();
  const { loadingAISpeech } = storeToRefs(openAI);
	
  recognition.onresult = (event) => {
    inputMessage.value = null;
    if (loadingAISpeech.value) {
      console.log("AI is speaking, please wait");
      return;
    }
    const last = event.results.length - 1;
    const command = event.results[last][0].transcript;
    inputMessage.value = command;
    console.log("Result received: " + command + ".");
  };

  recognition.onspeechend = () => {
    recognition.stop();
    console.log("Speaking stoped");
  };

  recognition.onend = () => {
    console.log("Voice Recognition Ended");
    if (startRecording.value) restartRecognition();
    else stopRecording();
  };

  recognition.onerror = (event) => {
    console.log("Error occurred in recognition: " + event.error);
  };

  const restartRecognition = () => {
    console.log("Speech restarted");
    recognition.start();
  };

  recognition.onspeechstart = () => {
    console.log("Speech has been detected");
  };
  const stopRecording = () => {
    startRecording.value = false;
    microphoneColor.value = "primary";
    startRecording.value;
  };
  const getMicrophonePermission = async () => {
    await navigator?.mediaDevices
      ?.getUserMedia({ audio: true })
      .then(() => {
        voicePermission.value = true;
        microphoneColor.value = "primary";
      })
      .catch(() => {
        voicePermission.value = false;
      });
  };

  getMicrophonePermission();
  const inputMessage = ref(null);
  stopRecording();

  // SPEECH SYNTHESIS
  let synthesis = null;
  if ("speechSynthesis" in window) {
    synthesis = window.speechSynthesis;
  } else {
    console.log("Speech synthesis not supported");
  }

  const configureSpeechSynthesis = () => {
    if (!synthesis) return;

    const voices = synthesis.getVoices();
    const voice = voices.find(
      (voice) => voice.lang === import.meta.env.VITE_APP_INPUT_VOICE_LANG
    );

    if (voice) {
      console.log("Selected Voice", voice);
      synthesis.voice = voice;
    }
  };

  const speakMessage = (message) => {
    if (!synthesis || !message) return;
    configureSpeechSynthesis();
    const utterance = new SpeechSynthesisUtterance(message);
    synthesis.speak(utterance);
  };

  const cancelSpeak = () => {
    if (!synthesis) return;
    synthesis.cancel();
  };

  return {
    recognition,
    startRecording,
    voicePermission,
    microphoneColor,
		handleVoiceClick,
		stopVoiceRecognition,
		startVoiceRecognition,
		stopRecording,
    inputMessage,

    speakMessage,
    cancelSpeak,
  };
});
