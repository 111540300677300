import { defineStore } from "pinia";
import { ref } from "vue";

export const useConfirmationStore = defineStore("confirmationStore", () => {
  const confirmationLoading = ref(false);
  const confirmationDialog = ref(false);
  const dialogData = ref({
    title: "Confirmation",
    subtitle: null,
    message: "Are you sure you want to continue?",
    color: "primary",
    icon: "warning", // success, warning, error, info
    confirmColor: "warning",
    cancelColor: "",
    confirmText: "Confirm",
    cancelText: "Cancel",
  });

  const promise = ref(null);

  const openConfirmationDialog = (data = {}) => {
    populateDialog(data);
    confirmationDialog.value = true;
    return new Promise((resolve) => {
      promise.value = resolve;
    });
  };

  const closeConfirmationDialog = () => {
    confirmationDialog.value = false;
    confirmationLoading.value = false;
    populateDialog();
  };

  const confirm = () => {
    promise.value(true);
    confirmationLoading.value = true;
  };

  const cancel = () => {
    promise.value(false);
    closeConfirmationDialog();
  };

  const populateDialog = (data = {}) => {
    dialogData.value.title = data.title || "Confirmation";
    dialogData.value.subtitle = data.subtitle || null;
    dialogData.value.message =
      data.message || "Are you sure you want to continue?";
		dialogData.value.color = data.color || "primary";
		dialogData.value.icon = data.icon || "warning";
		dialogData.value.confirmColor = data.confirmColor || "warning";
    dialogData.value.confirmText = data.confirmText || "Confirm";
    dialogData.value.cancelText = data.cancelText || "Cancel";
  };

  return {
    confirmationDialog,
		dialogData,
		confirmationLoading,
    openConfirmationDialog,
    closeConfirmationDialog,

    confirm,
    cancel,
  };
});
