<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import CardPostMain from "@/components/Cards/CardPostMain.vue";
import DialogPostForm from "@/components/Dialogs/DialogPostForm.vue";

// ROUTE
import { useRoute } from "vue-router";
const route = useRoute();

import { useCommunityStore } from "@/stores/communityStore";
const communityStore = useCommunityStore();
const { communityList, communityListLoading } = storeToRefs(communityStore);
const { setFetchQuery, fetchCommunityList, openPostDialog } =
  useCommunityStore();

const routeQuery = route.query;

const query = ref({
  per_page: 5,
  with_user: true,
  with_images: true,
  with_comments: true,
  order_by: "created_at",
  order: "desc",
  for_client_post_page: true,
  is_liked: true,
  is_hearted: true,
  is_bookmarked: true,
  created_by_id: routeQuery.created_by_id || null,
});

onMounted(() => {
  communityList.value = [];
  setFetchQuery(query.value);
  fetchCommunityList();
});
</script>

<template>
  <div class="main-content-container mb-5">
    <div class="text-left px-2 mb-4">
      <v-btn @click="openPostDialog()" variant="flat" density="comfortable">
        <template v-slot:default>
          <v-icon size="24" left color="#2457C5" class="mr-2">
            mdi-plus-circle
          </v-icon>
          <span class="text-btn"> Add New Post </span>
        </template>
      </v-btn>
    </div>
    <CardPostMain
      @message="fetchCommunityList"
      v-for="(post, index) in communityList"
      :key="index"
      bgColor="#FDD1B9"
      class="mb-3"
      :post="post"
      user="미셸"
    />
    <br />
    <DialogPostForm />
    <v-skeleton-loader
      v-if="communityListLoading && communityList.length == 0"
      type="card"
    ></v-skeleton-loader>
    <small class="" v-if="!communityListLoading && communityList.length == 0">
      No Data Found...</small
    >
  </div>
</template>

<style scoped>
.text-btn {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
}
.main-content-container {
  min-height: 100vh;
  height: 100%;
}
</style>
