<style scoped>
.title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: center;
  color: #8b475a;
}
.subtitle {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #8b475a;
	text-align: left;
}
</style>
<template>
  <div>
    <p class="title mb-4">Review</p>
    <v-row dense  align="center">
      <v-col cols="4" class="subtitle"> Fluency </v-col>
      <v-col cols="8" class="">
        <CustomeProgressBarVue :value="50" />
      </v-col>
    </v-row>
		<v-row dense  align="center">
      <v-col cols="4" class="subtitle"> Pronunciation </v-col>
      <v-col cols="8" class="">
        <CustomeProgressBarVue :value="50" />
      </v-col>
    </v-row>
		<v-row dense  align="center">
      <v-col cols="4" class="subtitle"> Accuracy </v-col>
      <v-col cols="8" class="">
        <CustomeProgressBarVue :value="50" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CustomeProgressBarVue from "@/components/CustomProgressBar.vue";
export default {
  components: {
    CustomeProgressBarVue,
  },
  setup() {},
};
</script>
