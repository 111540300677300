<style scoped>
.title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: left;
}
.header {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
}
.header-content {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.94px;
  text-align: left;
}
</style>
<template>
  <div class="main-content-container text-left pt-4 px-5">
    <p class="title mb-2 pl-2">Setting</p>
    <hr />
    <div class="d-flex align-center justify-space-between my-4">
      <p class="header d-flex align-center">
        <v-icon class="mr-2" size="40" color="#666666" left>
          mdi-account-cog
        </v-icon>
        <span class=""> Account </span>
      </p>
      <v-btn
        @click="$router.push({ name: 'profile-edit' })"
        density="compact"
        variant="icon"
        icon="mdi-pencil"
      >
      </v-btn>
    </div>
    <div class="header-content ml-3 pl-3 mt-5">
      <p class="mb-3 cursor-pointer">Profile Picture</p>
      <p class="mb-3 cursor-pointer">Jhon Doe</p>
      <p class="mb-3 cursor-pointer">myprofile@kokotalk.com</p>
    </div>
    <br />
    <div class="d-flex align-center justify-space-between my-4">
      <p class="header d-flex align-center">
        <v-icon class="mr-2" size="40" color="#666666" left>
          mdi-bell-ring</v-icon
        >
        <span class=""> Notifications </span>
      </p>
      <v-btn density="compact" variant="icon" icon="mdi-pencil"> </v-btn>
    </div>
    <div class="header-content ml-3 pl-3 mt-5">
      <p class="mb-3 cursor-pointer" @click="$router.push({ name : 'community'})">My Post</p>
      <p class="mb-3 cursor-pointer">Save Post</p>
      <p class="mb-3 cursor-pointer">Latest Comments</p>
    </div>
    <br />
    <div class="d-flex align-center justify-space-between my-4">
      <p
        class="header d-flex align-center cursor-pointer"
        @click="handleLogout"
      >
        <v-icon class="mr-2" size="40" color="#666666" left>
          mdi-logout-variant</v-icon
        >
        <span class=""> Logout </span>
      </p>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
const router = useRouter();
const handleLogout = () => {
  const authStore = useAuthStore();
  authStore
    .logout()
    .then(() => {
      router.push("/");
    })
    .catch((err) => {
      console.log(err);
    });
};
</script>
