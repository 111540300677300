import { defineStore } from "pinia";
import { ref } from "vue";
import * as api from "@/core/api";
import { useUserStore } from "@/stores/userStore";

export const useReactionStore = defineStore("reactionStore", () => {
  // as of now we only have like and heart reactions
  const loadingReaction = ref(false);
  const reaction_id = ref(null);
  const userStore = useUserStore();
  const USER = userStore.getCurrentUser;
  const handleNewReaction = async (
    model = "posts",
    model_id = false,
    type = false
  ) => {
		if (!model || !type || !model_id) return;
		
    loadingReaction.value = true;
    var res = false;
    const form = {
      type: type,
      reactable_type: model,
      reactable_id: model_id,
      user_id: USER.id,
    };
    await api
      .post(`/reactions`, form)
      .then((response) => {
				res = response;
      })
      .finally(() => {
        loadingReaction.value = false;
      });

    return res;
  };
  const handleDeleteReaction = async (id = false) => {
    if (!id) return;
    loadingReaction.value = true;
    var res = false;
    await api
      .destroy(`/reactions/${id}`)
      .then((response) => {
        res = response;
      })
      .finally(() => {
        loadingReaction.value = false;
      });

    return res;
  };

  return {
    reaction_id,
    handleNewReaction,
    loadingReaction,
    handleDeleteReaction,
  };
});
