<script setup>
import { ref, defineProps, watch } from "vue";
import { storeToRefs } from "pinia";

const props = defineProps({
  dialog: {
    type: Boolean,
    default: true,
  },
});

const show = ref(true);

watch(
  () => props.dialog,
  (val) => {
    show.value = val;
  },
  { immediate: true }
);

// AI Practice  Store
import { useAiPracticeStore } from "@/stores/aiPracticeStore";
const aiPracticeStore = useAiPracticeStore();
const { conversationHistory } = storeToRefs(aiPracticeStore);
</script>
<template>
  <v-dialog width="400" v-model="show" persistent>
    <v-card class="rounded-xl">
      <v-card-title class="text-left d-flex align-center justify-space-between">
        Conversation History
        <v-btn icon size="30" variant="text" @click="$emit('dialog', false)">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="history-container">
        <div v-if="conversationHistory.length > 0" class="messageBody">
          <div
            :class="`d-flex mb-2 ${chat.type == 'user' ? 'justify-end' : ''}`"
            v-for="(chat, index) in conversationHistory"
            :key="index"
          >
            <v-card
              :color="chat.type == 'ai' ? '#525252' : 'success'"
              :class="`text-left pa-3 mb-2 ${chat.type} message`"
            >
              {{ chat.message }}
            </v-card>
          </div>
        </div>
        <p class="text-caption" v-if="conversationHistory.length == 0">No Data Found ...</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.message {
  max-width: 70%;
}
.ai {
  border-radius: 10px 10px 10px 0;
}
.history-container {
	max-height: 300px;
	overflow-y: auto;
}

.user {
  border-radius: 10px 10px 0px 10px;
}
</style>
