<script setup>
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
const route = useRoute();
const ai_conversation_id = route.query.ai_conversation_id;
import CardRecomentedVideo from "@/components/Cards/CardRecommendedVideo.vue";
import YoutubePlayer from "@/components/Others/YoutubePlayer.vue";
import CardWord from "@/components/Cards/CardWord.vue";

import { useAiPracticeStore } from "@/stores/aiPracticeStore";
import { onMounted } from "vue";
const AIPracticeStore = useAiPracticeStore();
const { ActiveVideoLesson, aiPracticeVideoLesson, aiPracticeVideoLessonLoading } =
  storeToRefs(AIPracticeStore);
const { fetchAIPracticeVideoLesson } = AIPracticeStore;

onMounted(() => {
	ActiveVideoLesson.value = null;
	fetchAIPracticeVideoLesson(ai_conversation_id);
});
</script>
<template>
  <div class="main-content-container">
		<v-skeleton-loader v-if="aiPracticeVideoLessonLoading" type="image,article"></v-skeleton-loader>
		<no-data-found v-if="!aiPracticeVideoLessonLoading && !ActiveVideoLesson"/>
    <div v-if="ActiveVideoLesson && !aiPracticeVideoLessonLoading">
      <v-row class="">
        <v-col
          cols="4"
          lg="4"
          md="4"
          sm="4"
          v-for="(video, index) in aiPracticeVideoLesson.videos"
          :key="index"
        >
          <CardRecomentedVideo
            @click="ActiveVideoLesson = video"
            :bg="ActiveVideoLesson.id == video.id ? '#D9D9D9' : '#f1e1c2'"
            :active="ActiveVideoLesson.id == video.id ? true : false"
            :title="video.title_en"
            :img="video?.image?.path"
          />
        </v-col>
      </v-row>
      <div class="my-5 pa-4 youtube-container" v-if="ActiveVideoLesson">
        <p class="title mb-3">
          [ {{ ActiveVideoLesson.title_en }}] “{{
            ActiveVideoLesson.title_kor
          }}"
        </p>
        <v-card height="240">
          <YoutubePlayer :id="ActiveVideoLesson.youtube_link" />
        </v-card>
      </div>
      <div v-if="ActiveVideoLesson && ActiveVideoLesson.words && ActiveVideoLesson.words.length > 0">
        <p class="title mb-4">New Words</p>
        <v-row>
          <v-col
            cols="4"
            class=""
            v-for="(word, index) in ActiveVideoLesson.words"
            :key="index"
          >
            <CardWord
              :word_ko="word.word_ko"
              :word_en="word.word_en"
              :img="word.image.path"
            />
          </v-col>
        </v-row>
        <p class="mt-4 text-left font-weight-bold">#clothes #fashion</p>
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<style scoped>
.main-content-container {
  min-height: 100vh;
  height: 100%;
}
.youtube-container {
  background: #d9d9d9;
}
.title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: black;
  line-height: 24.2px;
  text-align: left;
}
</style>
