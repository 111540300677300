<style scoped>
.container {
  background: #c4f6ff;
  border-radius: 24px;
  padding: 5px;
}
.message {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
}
</style>
<template>
  <div class="d-flex container align-center">
    <div class="text-left px-4">
      <div class="d-flex align-center mb-2">
        <v-icon v-for="index in startCount" color="yellow" :key="index"
          >mdi-star</v-icon
        >
      </div>
      <p class="message">{{ message }}</p>
    </div>
    <div class="pa-3">
      <v-img width="60" height="60" :src="require(`@/assets/${img}`)" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    startCount: {
      type: Number,
      default: 2,
    },
    img: {
      type: String,
      default: "chat-special-cable-car.svg",
    },
    message: {
      type: String,
      default: "Welcome to Chungpung Hoban Cable Car",
    },
  },
  setup() {
    return {};
  },
};
</script>
