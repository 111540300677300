<script setup>
import { storeToRefs } from "pinia";
import { useSnackBarStore } from "@/stores/snackBarStore";
const snackBarStore = useSnackBarStore();
const { snackBar } = storeToRefs(snackBarStore);

</script>
<template>
  <v-snackbar variant="elevated" :time="snackBar.time" location="top right" :color="snackBar.color" v-model="snackBar.show" >
    <div class="d-flex">
      <div v-if="!snackBar.icon">
        <v-icon
          size="20"
          left
          color="success"
          v-if="snackBar.color == 'success'"
          >mdi-check</v-icon
        >
        <v-icon size="20" left color="info" v-if="snackBar.color == 'info'">{{
          icons.mdiInformationOutline
        }}</v-icon>
        <v-icon
          size="20"
          left
          color="warning"
          v-if="snackBar.color == 'warning'"
          >mdi-alert</v-icon
        >
        <v-icon size="20" left color="error" v-if="snackBar.color == 'error'"> mdi-alert-circle-outline </v-icon>
      </div>
      <v-icon small v-else> {{ snackBar.icon }} </v-icon>
      <div class="ml-2 mb-0" v-html="snackBar.text"></div>
    </div>
    <template v-slot:actions="{ attrs }">
      <v-btn  size="small" icon v-bind="attrs" @click="snackBar.show = false">
        <v-icon >mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
