<script setup>
import { onUnmounted, ref } from "vue";
import { loadScript, removeScript } from "@/plugins/load-script";
import { storeToRefs } from "pinia";

// USER STORE
import { useUserStore } from "@/stores/userStore";
const userStore = useUserStore();
const USER = userStore.getCurrentUser;

// Persistent Store
import { usePersistantStore } from "@/stores/persistentStore";
const persistentStore = usePersistantStore();
const { selected_subscription_id } = storeToRefs(persistentStore);

// PAYMENT STORE
import { usePaymentStore } from "@/stores/paymentStore";
const paymentStore = usePaymentStore();
const { paymentLoading, apiResponse} = storeToRefs(paymentStore);

// SNACKBAR STORE
import { useSnackBarStore } from "@/stores/snackBarStore";
const { showSnackBar } = useSnackBarStore();

const src = "https://pay.nicepay.co.kr/v1/js/";
loadScript(src);
const paymentList = [
  {
    id: 1,
    amount: 1004,
    plan: "Monthly",
    description: "Kokotalk - Monthly Subscription ",
  },
  {
    id: 2,
    amount: 1004,
    plan: "Yearly",
    description: "Kokotalk - Yearly Subscription ",
  },
];

const paymentData = ref({
  user_id: USER.id,
  amount: 1004,
  payment_method: null,
  subscription_id: 1,
});


const ReturnURL = `${process.env.VUE_APP_URL}/nice-payment-response`;
console.log("ReturnURL", ReturnURL);

onUnmounted(() => removeScript(src));
selected_subscription_id.value = null
const handdleNicePayment = (plan) => {
  const payment = paymentList.find((p) => p.plan === plan);
  paymentData.value.subscription_id = payment.id;
	selected_subscription_id.value = payment.id
  window.AUTHNICE.requestPay({
    clientId: "S2_af4543a0be4d49a98122e01ec2059a56", // clientId에 따라 Server / Client 방식 분리
    method: "card",
    orderId: "32d1e681-63ef-482d-abeb-2d934e9d8f08",
    amount: payment.amount,
    goodsName: payment.description,
    vbankHolder: "나이스",
    returnUrl: ReturnURL,
		user_id : USER.id,
    fnSuccess: function (response) {
			apiResponse.value = response
			console.log(response);
      // paymentData.value.resultCode = response.resultCode;
      // paymentData.value.resultMsg = response.resultMsg;
      // paymentData.value.signature = response.resultMsg;
      // paymentData.value.ediDate = response.ediDate;
      // paymentData.value.isValid = response.isValid;
      // paymentData.value.tid = response.tid;
      // handlePayment(paymentData);
			// router.push({ name: "payment-success" });
      // 결제 성공 시 처리할 로직
    },

    // 결제 에러 발생 시 호출될 함수 (필수)
    fnError: function (error) {
			console.log("Payment error:", error);
			showSnackBar({
				text : error.errorMsg,
				color : 'error'
			});
    },
  });
};
</script>
<template>
  <div class="subscription-container pa-5">
    <p class="title">Choose your Subscription Plan</p>
    <v-row dense class="mx-0">
      <v-col cols="12" lg="6" md="6" sm="6" class="text-center">
        <v-card color="#98d5cc" class="elevation-0 py-5" :loading="paymentLoading">
          <div class="plan monthly mb-5">
            <p>
              <span class="amount">$10</span>
              <span class="period">/mo</span>
            </p>
            <p class="plan-title">Monthly</p>
            <p class="plan-description px-3 mt-1">month-to-month flexibility</p>
          </div>
          <v-btn
            @click="handdleNicePayment('Monthly')"
            width="140"
            class="rounded-pill action-button elevation-0"
            >Select
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6" class="text-center">
        <v-card color="#e8b6c4" class="elevation-0 py-5" :loading="paymentLoading">
          <div class="plan yearly mb-5">
            <p>
              <span class="amount">$96</span>
              <span class="period">/yr</span>
            </p>
            <p class="plan-title">Yearly</p>
            <p class="plan-description px-3 mt-1">
              pay upfront and <b> save 20% </b>
            </p>
          </div>
          <v-btn
            @click="handdleNicePayment('Yearly')"
            width="140"
            class="rounded-pill action-button elevation-0"
            >Select
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.plan {
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}
.monthly {
  background: #98d5cc;
}
.yearly {
  background: #e8b6c4;
}
.amount {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 48.41px;
  text-align: center;
  color: #00000099;
}
.period {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.15px;
  text-align: center;
  color: #4e6266;
}
.plan-title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: center;
  margin-top: 20px;
}
.plan-description {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: center;
}
.action-button {
  background: #ff528a;
  color: white;
}
</style>
