<script setup>
import { storeToRefs } from "pinia";
import { defineProps } from "vue";

defineProps({
	disabled: {
		type: Boolean,
		default: false,
	},
});

import { useVoiceInputStore } from "@/stores/voiceInputStore";
const voiceInputStore = useVoiceInputStore();
const { startRecording, voicePermission } = storeToRefs(voiceInputStore);
const { handleVoiceClick } = useVoiceInputStore();


</script>

<template>
  <v-tooltip location="bottom">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        @click="handleVoiceClick"
        icon
				:disabled="disabled || !voicePermission"
				background-color="primary"
				:variant="startRecording ? 'elevated' : 'flat'"
        :color="startRecording ? 'success' : 'error'"
        :class="`btn-speak elevation-10 ${startRecording ? 'active' : ''} elevation-5`"
      >
        <v-icon size="50">
          {{
            startRecording
              ? "mdi-microphone"
              : "mdi-microphone-off"
          }}</v-icon
        >
      </v-btn>
    </template>
    <div>
      <span v-if="!voicePermission">
        Permission for Microphone is not allowed
      </span>
      <span v-if="voicePermission && startRecording"> Mute </span>
      <span v-if="voicePermission && !startRecording"> Unmute </span>
    </div>
  </v-tooltip>
</template>

<style scoped>
.btn-speak {
  height: 70px;
  width: 70px;
  /* center  */
}
.active {
  outline-width: 1px;
  outline-offset: 0;
  outline-color: rgb(255, 255, 255);
  outline-style: solid;
  animation: animateOutline 2s ease infinite;
}

@keyframes animateOutline {
  0% {
    outline-width: 1px;
    outline-offset: 0;
    outline-color: rgba(0, 130, 206, 0);
  }

  10% {
    outline-color: rgb(74, 246, 0);
  }

  /* The animation finishes at 50% */
  50% {
    outline-width: 10px;
    outline-offset: 4px;
    outline-color: rgba(0, 130, 206, 0);
  }

  100% {
    outline-width: 7px;
    outline-offset: 4px;
    outline-color: rgba(102, 102, 102, 0);
  }
}
</style>
