import { defineStore } from "pinia";
import { ref } from "vue";
import * as api from "@/core/api";
export const useSubCategoryStore = defineStore(
  "subCategoryStore",
  () => {
    const subCategoryList = ref([]);
    const subCategoryListLoading = ref(false);
    const subCategoryQuery = ref({});

    const setSubCategoryQuery = (query) => {
      subCategoryQuery.value = query;
    };

    const fetchSubCategoryList = async () => {
			subCategoryListLoading.value = true;
			let res = null;
      await api
        .get("/sub-categories", subCategoryQuery.value)
        .then((response) => {
          subCategoryList.value = response.data;
          res = response;
        })
        .finally(() => {
          subCategoryListLoading.value = false;
        });
			
			return res;
    };

		return {
			// Sub Category List Page
			subCategoryList,
			subCategoryListLoading,
			subCategoryQuery,
			setSubCategoryQuery,
			fetchSubCategoryList,
    };
  },
  {

  }
);
