import { defineStore } from "pinia";
import * as api from "@/core/api";
import { ref } from "vue";

export const useVideoStore = defineStore("videoStore", () => {
  const videoList = ref([]); // generate sample video data with the following data structure
  const videoListLoading = ref(false);
  const videoQuery = ref({});
  // generate sample video data with the following data structure

  const setVideoListQuery = (query) => {
    videoQuery.value = query;
  };

  const fetchVideoList = async () => {
    videoList.value = [];
    videoListLoading.value = true;
    await api
      .get("/videos", videoQuery.value)
      .then((response) => {
        videoList.value = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        videoListLoading.value = false;
      });
  };

  // VIDEO DETAIL PAGE
  const videoDetail = ref({});
  const videoDetailLoading = ref(false);
  const fetchVideoDetail = async (id) => {
    videoDetail.value = {};
    videoDetailLoading.value = true;
    await api
      .get(`/videos/${id}`)
      .then((response) => {
        videoDetail.value = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        videoDetailLoading.value = false;
      });
  };

  return {
    // LIST PAGE
    videoList,
    videoListLoading,
    videoQuery,
    setVideoListQuery,
    fetchVideoList,

    // VIDEO DETAIL PAGE
    videoDetail,
    videoDetailLoading,
    fetchVideoDetail,
  };
});
