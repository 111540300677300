<script setup>
import { defineProps, onMounted, ref, watch } from "vue";
import PostComment from "@/components/PostComment.vue";
import CardPostComments from "@/components/Cards/CardPostComments.vue";
import { useCommentStore } from "@/stores/commentStore";

const { fetchCommentList, setCommentFetchQuery } = useCommentStore();

// USER
import { useUserStore } from "@/stores/userStore";
const userStore = useUserStore();
const USER = userStore.currentUser;

// POST
import { useCommunityStore } from "@/stores/communityStore";
const { openPostDialog, handleDeletePost, handleDeletePostComment } =
  useCommunityStore();

const props = defineProps({
  post: {
    type: Object,
    default: () => {},
  },
  bgColor: {
    type: String,
    default: "#F1E1C2",
  },
  user: {
    type: String,
    default: "Clara",
  },
});
const comments = ref([]);

const loadingComment = ref(false);
const loadPostComments = async () => {
  setCommentFetchQuery({
    per_page: 3,
    commentable_type: "posts",
    commentable_id: props.post.id,
    with_user: true,
    order_by: "created_at",
    order: "desc",
  });

  loadingComment.value = true;
  await fetchCommentList()
    .then((res) => {
      comments.value = res.data;
    })
    .finally(() => {
      loadingComment.value = false;
    });
};

watch(
  () => props.post,
  () => {
    comments.value = props.post.comments;
  },
  {
    deep: true,
  }
);

onMounted(() => {
  comments.value = props.post.comments;
});

// LIKE COMPONENT
import PostReaction from "@/components/Icons/PostReaction.vue";
// BOOKMARK COMPONENT
import BookMark from "@/components/Icons/IconBookmark.vue";
</script>

<template>
  <div class="container" :style="`background : ${bgColor}`">
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-avatar>
          <v-img
            :src="
              post.user.image?.path ||
              require('@/assets/community-user-smile.svg')
            "
            height="40"
            width="40"
          />
        </v-avatar>
        <p class="pl-2 user">{{ post.user?.name }}</p>
      </div>
      <div class="d-flex align-center">
        <PostReaction
          :model_id="post.id"
          :id="post.is_liked ? post.is_liked.id : null"
        />
        <PostReaction
          :model_id="post.id"
          :id="post.is_hearted ? post.is_hearted.id : null"
          type="heart"
          color="#EC0913"
        />
        <BookMark
          :model_id="post.id"
          :id="post.is_bookmarked ? post.is_bookmarked.id : null"
        />
        <v-menu v-if="post.created_by_id == USER.id">
          <template v-slot:activator="{ props }">
            <v-btn
              variant="text"
              icon="mdi-dots-vertical"
              size="small"
              density="comfortable"
              v-bind="props"
            >
            </v-btn>
          </template>
          <v-list density="compact" class="py-0">
            <v-list-item link density="compact" @click="openPostDialog(post)">
              <v-list-item-title class="text-left"
                ><v-icon size="small" class="mr-1">mdi-pencil</v-icon>
                Edit</v-list-item-title
              >
            </v-list-item>
            <v-list-item link density="compact" @click="handleDeletePost(post)">
              <v-list-item-title class="text-left">
                <v-icon size="small" class="mr-1">mdi-delete-outline</v-icon>
                Delete</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="content mt-3 rounded">
      <p class="content-title">{{ post?.title }}</p>
      <div class="content-posted">
        <div
          class="d-flex justify-space-around align-center"
          v-if="post.images"
        >
          <v-img
            v-if="post.images.length == 1"
            :src="post.images[0].path"
            contain
            height="150"
            width="100%"
          />
          <div v-else class="text-left d-flex flex-wrap">
            <div
              v-for="(image, index) in post.images"
              :key="index"
              class="mr-2 mb-2"
            >
              <v-img
                class=""
                :src="image.path"
                contain
                height="150"
                width="130"
              />
            </div>
          </div>
        </div>
        <p class="mt-2 px-3 mb-2 posted-text text-left">
          {{ post?.description }}
        </p>
      </div>
    </div>
    <div class="mt-5">
      <CardPostComments
        :comments="comments"
        :user="USER"
        @deletePostComment="handleDeletePostComment"
      />
      <PostComment
        @message="loadPostComments"
        :api="true"
        :model="post"
        :user="USER"
      />
    </div>
  </div>
</template>

<style scoped>
.container {
  border-radius: 24px;
  padding: 10px;
}
.user {
  font-family: "SOYO Maple Bold";
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
}
.content {
  background: white;
  padding: 5px;
}
.content-title {
  font-family: "SOYO Maple Bold";
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  color: black;
  padding: 0 24px;
}
.posted-text {
  font-family: "SOYO Maple Bold";
  font-size: 14px;
  line-height: 16.94px;
  text-align: center;
  color: #424242;
}
</style>
