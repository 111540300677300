<script setup>
import { defineProps, ref } from "vue";
const props = defineProps({
  word_en: String,
  word_ko: String,
  img: String,
});

// OPEN AI
// import { useOpenAIStore } from "@/stores/openAIStore";
// const { AITextToSpeech } = useOpenAIStore();
import { useTTS } from "@/composables/useTTS";
const { ttsText, play } = useTTS()

const loadingAISpeech = ref(false);
const handleTextToSpeech = async () => {
  // // OpenAI
	// var text = props.word_ko;
	// console.log(text, 'speaking');
  // loadingAISpeech.value = true;
  // const audioUrl = await AITextToSpeech(text, true);
  // if (!audioUrl) return;
  // // Create an Audio object and set the source to the audio URL
  // const audio = new Audio(audioUrl);

  // // Play the audio
  // audio.play();

  // audio.onended = () => {
  //   loadingAISpeech.value = false;
  // };

  // useSpeechSynthesis composable
	ttsText.value = props.word_ko
	console.log(ttsText.value, 'speaking')
  loadingAISpeech.value = true
  
  await play()

  loadingAISpeech.value = false
};
</script>

<template>
  <v-card
    @click="handleTextToSpeech()"
    :loading="loadingAISpeech"
    :disabled="loadingAISpeech"
    variant="flat"
    rounded="lg"
    height="110"
    width="100%"
    class="card-word d-flex align-center justify-center pr-3"
  >
    <div class="icon-center" v-if="loadingAISpeech">
      <v-icon  class="icon-tilt" color="error" size="x-large"
        >mdi-volume-high</v-icon
      >
    </div>
    <v-img
      height="50"
      contain
      :src="img"
      class="d-flex align-center justify-center"
    >
    </v-img>
    <div>
      <p class="font-weight-bold">{{ word_ko }}</p>
      <p class="font-weight-bold">{{ word_en }}</p>
    </div>
  </v-card>
</template>
<style scoped>
.card-word {
  width: 100%;
  border: 1px solid #86e7f9;
  background: #c4f6ff;
}
.icon-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.icon-tilt {
	animation: tilt-shaking .3s infinite;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
