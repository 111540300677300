import axios from "axios";
import { useRouter } from "vue-router";
const router = useRouter();


/**
 * initialized axios
 */
const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/v1/`,
});

// setup the api key & secret
axiosInstance.defaults.headers.common["Accept"] = "application/json";

/**
 * * Note: no bearer token as it not needed for now
 */

// Add an interceptor to attach the token to each request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add an interceptor to handle responses
axiosInstance.interceptors.response.use(
  (response) => {
    let data = response.data;
    return Promise.resolve(data);
  },
  async (error) => {
		if (error.response.status == 401) {
      if (error.response.data.message == "Unauthenticated.") {
				localStorage.removeItem("token");
				localStorage.removeItem("userStore");
        router.push({ name: "login" });
      }
    }
    return Promise.reject(error.response);
  }
);

export default axiosInstance;
