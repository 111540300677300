import { defineStore } from "pinia";
import { ref } from "vue";

export const useSnackBarStore = defineStore("snackBarStore", () => {

	const snackBar = ref({
    show: false,
    text: "",
    color: "primary",
    time: 6000,
    icon: "mdi-information-outline",
    type: "loading",
    absolute: false,
    multiline: false,
  });

	const showSnackBar = (data = {}) => {
		snackBar.value.show = true
		snackBar.value.text = data.text || ""
		snackBar.value.color = data.color || "primary"
		snackBar.value.time = data.time || 6000;
		snackBar.value.icon = data.icon || "mdi-information-outline";
		snackBar.value.type = data.type || 'loading'
		snackBar.value.absolute = data.absolute || false
		snackBar.value.multiline = data.multiline || false
	}

	return {
		snackBar,
    showSnackBar,
  };
});