<script setup>
import { storeToRefs } from "pinia";
import { useConfirmationStore } from "@/stores/confirmationStore";
const confirmationStore = useConfirmationStore();
const { confirmationDialog, dialogData, confirmationLoading } = storeToRefs(confirmationStore);
const { confirm, cancel } = useConfirmationStore();

</script>

<template>
	<v-dialog width="350" persistent v-model="confirmationDialog">
		<v-card :loading="confirmationLoading" :disabled="confirmationLoading" >
			<v-card-title class="text-left d-flex align-center">
				<v-icon :color="dialogData.icon" v-if="dialogData.icon == 'info'">mdi-information-outline</v-icon>
				<v-icon :color="dialogData.icon" v-if="dialogData.icon == 'warning'">mdi-alert-outline</v-icon>
				<v-icon :color="dialogData.icon" v-if="dialogData.icon == 'error'">mdi-alert-circle-outline</v-icon>
				<v-icon :color="dialogData.icon" v-if="dialogData.icon == 'success'">mdi-check-bold</v-icon>
				<span class="ml-3"> {{ dialogData.title  }} </span>
				</v-card-title>
			<v-card-subtitle class="text-left">{{ dialogData.subtitle }}</v-card-subtitle>
			<v-card-text class="text-left">
				{{ dialogData.message }}
			</v-card-text>
			<v-card-actions class="pa-4">
				<v-btn :color="dialogData.cancelColor" @click="cancel">Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn variant="tonal" class="elevation-4 rounded-lg" :color="dialogData.confirmColor" @click="confirm">{{ dialogData.confirmText }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>