<script setup>
import { ref } from "vue";
const selectedDate = ref(null);

import SocialReaction from "@/components/SocialReaction.vue";
</script>

<template>
  <div class="main-content-container mb-5">
    <div class="post-container pa-2">
      <div class="mb-2 d-flex align-center justify-space-between">
				<v-icon color="primary">mdi-account</v-icon>
				<SocialReaction />
			</div>

      <div class="form-container">
        <p class="title font-weight-bold">[Local Restaurant Tour] Taste Real Local Chungbuk</p>
        <v-date-picker
          border
          tile
          hide-actions
          hide-title
          color="error"
          width="100%"
          header="Select Date"
          rounded="xl"
					class="mt-3"
          v-model="selectedDate"
        >
          <template v-slot:title=""> </template>
        </v-date-picker>
        <br />
        <v-text-field
          label="Full name"
          hide-details
          variant="outlined"
          class="mb-2"
          density="compact"
          rounded="lg"
        />
        <v-text-field
          label="Contact Number"
          variant="outlined"
          density="compact"
          height="200"
        />
      </div>
    </div>
    <br />
    <v-btn variant="tile" height="50" width="150" color="white" append-icon="mdi-currency-usd" class="btn" rounded="pill"> Pay Now </v-btn>
  </div>
</template>
<style scoped>
.post-container {
  background: #f1e1c2;
  border-radius: 15px;
}
.form-container {
  background: white;
  padding: 10px;
}
.title {
  width: 220px;
  margin: 0 auto;
	line-height: 20px;
}
.v-date-picker-header {
  display: none;
}
.btn{
	background-color: #FF528A;
}
</style>
