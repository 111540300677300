<script setup>
import { storeToRefs } from "pinia";
import { useVideoStore } from "@/stores/videoStore";
const videoStore = useVideoStore();
const { videoList, videoListLoading } = storeToRefs(videoStore);
const { fetchVideoList, setVideoListQuery } = useVideoStore();
import YoutubePlayer from "@/components/Others/YoutubePlayer.vue";

setVideoListQuery({
  for_video_list_page: true,
});
fetchVideoList();
</script>

<template>
  <div class="main-content-container mb-5">
    <v-row
      class="mx-0 px-4 mb-4"
      v-if="videoListLoading && videoList.length == 0"
    >
      <v-col cols="12">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mx-0 px-4 mb-4" v-if="videoList.length > 0">
      <v-col
        cols="12"
        lg="4"
        md="6"
        sm="12"
        v-for="(video, index) in videoList"
        :key="index"
      >
        <div
          class="youtube-container"
          @click="
            $router.push({ name: 'video-detail', query: { id: video.id } })
          "
        >
          <v-card height="200" variant="flat" class="rounded-0" v-if="video">
            <YoutubePlayer :id="video?.youtube_link" :title="video.title_en" />
          </v-card>
          <p class="text-left font-weight-bold my-2">
            {{ video.title_en }}
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.video-container {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.youtube-container {
  position: relative;
  cursor: pointer;
}
.youtube-container::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 20;
  left: 0;
  top: 0;
  clear: both;
}
.main-content-container {
  min-height: 100vh;
  height: auto;
}
</style>

<style>
.yt-lite {
  min-height: 200px !important;
}
</style>
