<style scoped>
.container {
  height: 100vh;
}
.icon-container {
  height: 120px;
}
.form-container {
  background: white;
  border-radius: 24px;
  padding: 30px;
  width: 300px;
}
.title {
  color: #ff528a;
}
.tilt {
  transform: rotate(17deg);
  margin-left: -5px;
}
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(90deg);
}
.hint {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 15px;
  font-weight: 300;
  margin-top: 19px;
  text-align: left;
}
.btn-register {
  background: #ff528a;
  color: white;
}
</style>
<template>
  <div class="d-flex align-center justify-center flex-column container">
    <div class="icon-container">
      <v-img width="150" height="86" src="../assets/signup-kokotalk-icon.svg" />
    </div>
    <v-form ref="validateLogin" @submit.prevent="handleLogin" class="form-container">
      <h1 class="title mb-3">
        hello
        <div class="tilt d-inline-block">!</div>
      </h1>
      <v-text-field
        v-model="formData.email"
        class="mb-3"
        height="50"
        color="#F7A2C1"
        base-color="#F7A2C1"
        :rules="rules.email"
        rounded
        variant="outlined"
        hide-details
        required
        type="email"
      >
        <template v-slot:label>
          <span class="input__label title">email</span>
        </template>
        <template v-slot:prepend-inner>
          <v-img
            width="30"
            height="30"
            src="../assets/signup-textfield-user-icon.svg"
          />
        </template>
      </v-text-field>
      <v-text-field
        v-model="formData.password"
        class="mb-3"
        height="50"
        color="#F7A2C1"
        base-color="#F7A2C1"
        rounded
        variant="outlined"
        hide-details
        :rules="rules.password"
        :type="showPassword ? 'text' : 'password'"
        :append-inner-icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye'"
        @click:append-inner="() => (showPassword = !showPassword)"
      >
        <template v-slot:label>
          <span class="input__label title">Password</span>
        </template>
        <template v-slot:prepend-inner>
          <v-img
            width="26"
            height="26"
            src="../assets/signup-textfield-password-icon.svg"
          />
        </template>
      </v-text-field>
      <v-alert
        closable
        @click:closable="incorrectCredentials = false"
        density="compact"
        v-if="incorrectCredentials"
        type="warning"
        class="text-left"
      >
        Invalid email or password
      </v-alert>
      <v-btn
        rounded
        variant="outlined"
        class="action-button mt-5 btn-register"
        type="submit"
        :loading="loading"
        >Login</v-btn
      >
    </v-form>
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
// eslint-disable-next-line no-unused-vars
const router = useRouter();
const authStore = useAuthStore();
const {
  formData,
  showPassword,
  loading,
  incorrectCredentials,
  rules,
  validateLogin,
} = storeToRefs(authStore);

const handleLogin = () => {
  validateLogin.value?.validate().then(({ valid: isValid }) => {
    if (isValid) {
      authStore.login().then((response) => {
        if (response) router.push("/home");
      });
    }
  });
};
</script>
