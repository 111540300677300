<script setup>
import { defineProps, onMounted, ref } from "vue";

import { useReactionStore } from "@/stores/reactionStore";
const { handleNewReaction, handleDeleteReaction } = useReactionStore();

const props = defineProps({
  model: {
    type: String,
    default: "posts",
  },
  model_id: {
    type: Number,
    default: null,
  },
  id: {
    type: Number,
    default: null,
  },
  type: {
    type: String,
    default: "like",
  },
  color: {
    type: String,
    default: "#2095F2",
  },
});
const reaction_id = ref(null);
const loadingReaction = ref(false);
onMounted(() => {
  reaction_id.value = props.id;
});

const handleReaction = async () => {
  loadingReaction.value = true;
  if (reaction_id.value)
    await handleDeleteReaction(reaction_id.value).then(() => {
      reaction_id.value = null;
    });
  else
    await handleNewReaction(props.model, props.model_id, props.type).then(
      (res) => {
        reaction_id.value = res.data.id;
      }
    );

  loadingReaction.value = false;
};
</script>
<template>
  <v-btn
    @click="handleReaction"
    :loading="loadingReaction"
    icon
    size="24"
    class="mr-2 elevation-0"
    :color="reaction_id ? color : 'white'"
    density="comfortable"
  >
    <v-icon :color="reaction_id ? 'white' : ''" size="14">{{
      type == "like" ? "mdi-thumb-up" : "mdi-heart"
    }}</v-icon>
  </v-btn>
</template>
