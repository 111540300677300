<style scoped>
.item {
  height: 110px;
  width: 100%;
	border-radius: 12px;
}
</style>
<template>
  <div>
    <!-- <p class="content-title mb-4">Recommend Lessons</p> -->
    <v-row>
      <v-col cols="4" dense>
        <v-card variant="flat" link @click="$router.push({ name: 'video-lesson'})"
          class="item d-flex align-center justify-center"
          style="background: #f1e1c2;"
        >
          <div>
            <v-img
              height="70"
              width="70"
              src="@/assets/card-special-stick-profile.svg"
            />
            <p class="message">"있나요?"</p>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4" dense>
        <div
          class="item d-flex align-center justify-center"
          style="background: #fdd1b9"
        >
          <div>
            <v-img
              height="70"
              width="70"
              src="@/assets/card-special-color-wheel.svg"
            />
            <p class="message">"있나요?"</p>
          </div>
        </div>
      </v-col>
      <v-col cols="4" dense>
        <div
          class="item d-flex align-center justify-center"
          style="background: #e8b6c4"
        >
          <div>
            <v-img
              height="70"
              width="70"
              src="@/assets/chat-special-cash.svg"
            />
            <p class="message">"있나요?"</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
