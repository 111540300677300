<style scoped>
.player {
  width: 100%;
  height: 163px;
  margin-top: 40px;
}
</style>
<template>

  <div class="main-content-container">
    <TextFieldSearch class="py-3 px-3" />
    <BreadCrumb :items="['Travel', 'Sightseeing']" class="pl-2" />
    <v-row dense class="mt-4 ">
      <v-col cols="6">
        <CardChatItemCategory style="cursor:pointer;" @click="$router.push({ name: 'ai-practice-conversation'}) " />
      </v-col>
      <v-col cols="6">
        <CardChatItemCategory
					style="cursor:pointer;" @click="$router.push({ name: 'ai-practice-conversation'}) "
          title="Hanbok <br> at Rental Shop"
          img="chat-hanbok-icon.svg"
          imgHeight="100"
          imgWidth="90"
          bgColor="#E9FCFF"
          borderColor="#BFD69B"
          :startCount="2"
        />
      </v-col>
      <v-col cols="6">
        <CardChatItemCategory
          title="Asking for  taking <br> a picture"
          img="chat-camera-icon.svg"
          imgHeight="90"
          imgWidth="90"
          bgColor="#E3F1CD"
          borderColor="#59DFF7"
          :startCount="3"
        />
      </v-col>
      <v-col cols="6">
        <CardChatItemCategory
          title="Eating Ramen at <br> Han river"
          img="chat-noodle-icon.svg"
          imgHeight="95"
          imgWidth="95"
          bgColor="#E8B6C4"
          borderColor="#BFD69B"
          :startCount="3"
        />
      </v-col>
      <v-col cols="6">
        <CardChatItemCategory
          title="Ordering Chicken <br> to the hotel"
          img="chat-chicken-icon.svg"
          imgHeight="90"
          imgWidth="88"
          bgColor="#F1E1C2"
          borderColor="#59DFF7"
          :startCount="3"
        />
      </v-col>
      <v-col cols="6">
        <CardChatItemCategory
          title="Love Key Chain <br> at Namsan"
          img="chat-heart-icon.svg"
          imgHeight="90"
          imgWidth="88"
          bgColor="#F1E1C2"
          borderColor="#59DFF7"
          :startCount="3"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { ref } from "vue";

import TextFieldSearch from "@/components/TextFieldSearch.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";

import CardChatItemCategory from "@/components/Cards/CardItemChatCategory.vue";
export default {
  components: {
    TextFieldSearch,
    BreadCrumb,
    CardChatItemCategory,
  },
  setup() {
    const videoOptions = ref({
      autoplay: true,
      controls: true,
    });
    return {
      videoOptions,
    };
  },
};
</script>
