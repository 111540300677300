<script setup>
import { defineEmits, defineProps, ref } from "vue";
import { useCommentStore } from "@/stores/commentStore";
const { handleCommentSubmit } = useCommentStore();
const comment = ref(null);
const loading = ref(false);
const props = defineProps({
  api: {
    type: Boolean,
    default: false,
  },
  model: {
    type: Object,
    default: () => {},
  },
  user: {
    type: Object,
    default: () => {},
  },
});

comment.value = null;

const emit = defineEmits(["message"]);
const sendMessage = async () => {
  if (!comment.value) return;

  var form = {
    commentable_id: props.model?.id,
    comment: comment.value,
    commentable_type: "posts",
    user_id: props.user?.id ?? null,
  }

  if (props.api) {
    loading.value = true;
    await handleCommentSubmit(form)
      .then(() => {
        emit("message", comment.value);
        comment.value = null;
      })
      .finally(() => (loading.value = false));
  } else {
    emit("message", comment.value);
    comment.value = null;
  }
};
</script>

<template>
  <v-card
    :disabled="loading"
    :loading="loading"
    variant="flat"
    class="container d-flex align-center justify-space-between"
  >
    <form
      @submit.prevent="sendMessage()"
      class="container-input d-flex align-center justify-center"
    >
      <v-text-field
        density="compact"
        hide-details
        label=""
        bg-color="white"
        rounded="pill "
        variant="solo"
        flat
        class="elevation-0 input"
        v-model="comment"
        placeholder="Type a message"
      >
        <template v-slot:append>
          <v-btn
            color="#EB3223"
            variant="text"
            type="submit"
            density="compact"
            icon="mdi-send"
          >
          </v-btn>
        </template>
      </v-text-field>
    </form>
  </v-card>
</template>
<style scoped>
.container {
  padding: 12px;
  background: #f1e1c2;
  border-radius: 24px;
  width: 100%;

  max-width: 1024px;
}
.min-width {
  width: 40%;
}
.input {
  font-size: 13px !important;
}
.container-input {
  width: 100%;
}
.fixed {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
