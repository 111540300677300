<style scoped>
.container {
  height: 150px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 10px;
  position: relative;
}
.title {
  font-family: "SOYO Maple Bold";
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
	position: relative;
	z-index: 5;
}
.img {
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
}
</style>
<template>
  <div>
		<div class="d-flex align-center justify-end">
			<v-icon v-for="index in startCount" color="yellow" :key="index">mdi-star</v-icon>
		</div>
    <div
      class="container"
      :style="`background : ${bgColor}; border : 1px solid ${borderColor}`"
    >
      <p class="title" v-html="title"></p>
      <v-img
        class="img"
        :height="imgHeight"
        :width="imgWidth"
        :src="require(`@/assets/${img}`)"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: "#C4F6FF",
    },
    borderColor: {
      type: String,
      default: "#59DFF7",
    },
    title: {
      type: String,
      default: "Entrance of Kyoungbok Palace",
    },
    img: {
      type: String,
      default: "chat-castle-icon.svg",
    },
    imgHeight: {
      type: String,
      default: "100",
    },
    imgWidth: {
      type: String,
      default: "100",
    },
		startCount: {
			type: Number,
			default: 3
		}
  },
};
</script>
