<script setup>
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/userStore";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { watch, ref } from "vue";
import DialogMainMenu from "@/components/Dialogs/DialogMainMenu.vue";
const route = useRoute();
const active = ref("");
const router = useRouter();
watch(
  () => route,
  (val) => {
    if (val && val.meta && val.meta.tab) {
      active.value = val.meta.tab;
    } else {
      active.value = "";
    }
  },
  { deep: true, immediate: true }
);

const handleLogout = () => {
  const authStore = useAuthStore();
  authStore
    .logout()
    .then(() => {
      router.push("/");
    })
    .catch((err) => {
      console.log(err);
    });
};

const menuDialog = ref(false);

// user Store
const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);
</script>

<template>
  <div
    class="container d-flex align-center justify-space-between"
    v-if="active"
  >
    <div class="d-flex justify-space-between align-center pl-4 pr-2 w-100">
      <div style="cursor: pointer" @click="$router.push({ name: 'home' })">
        <v-img height="33" width="56" src="@/assets/header-icon.svg" />
      </div>
      <div class="d-flex align-center">
        <v-img
          width="56"
          height="33"
          src="@/assets/header-translate-icon.svg"
        />
        <div class="container-user mr-3">
          <small class="d-block">{{ currentUser?.name }}</small>
          <small>{{ currentUser?.email }}</small>
          <!-- <small>자민</small> -->
        </div>
        <v-avatar>
          <v-img
            class="cursor-pointer"
            @click="$router.push({ name: 'profile' })"
            width="40"
            height="40"
            :src="
              currentUser?.image?.path ||
              require('@/assets/header-cat-icon.svg')
            "
          />
        </v-avatar>
      </div>
    </div>
    <div class="menu-container d-flex align-center justify-center">
      <v-btn
        @click="menuDialog = !menuDialog"
        class="style-button"
        height="64"
        variant="text"
        width="64"
      >
        <template v-slot:default>
          <v-icon size="50">mdi-menu</v-icon>
        </template>
      </v-btn>
    </div>
    <DialogMainMenu
      :dialog="menuDialog"
      @logout="handleLogout"
      @dialog="menuDialog = $event"
    />
  </div>
</template>

<style scoped>
.container-user {
  line-height: 15px;
  text-align: right;
}
.menu-title {
  font-size: 12px;
}
.menu-item {
  min-height: 30px !important;
  border-bottom: 1px solid #ccc;
}
.menu-container {
  background: white;
  height: 64px;
  width: 64px;
  border-top-left-radius: 20px;
}
.style-button {
  border-radius: 20px 0 0 0;
}
</style>
