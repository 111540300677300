<style scoped>
.container {
  height: 230px;
  border-radius: 24px;
  padding: 10px;
  position: relative;
}
.title {
  font-family: "SOYO Maple Bold";
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
	text-transform: uppercase;
}
.img {
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
}
</style>
<template>
  <div>
    <div
      class="container"
      :style="`background : ${bgColor}; border : 1px solid ${borderColor}`"
    >
      <p class="title" v-html="title"></p>
      <v-img
        class="img"
        :height="imgHeight"
        :width="imgWidth"
        cover
        :src="img"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: "#D3C6F6",
    },
    borderColor: {
      type: String,
      default: "#59DFF7",
    },
    title: {
      type: String,
      default: "Entrance of Kyoungbok Palace",
    },
    img: {
      type: String,
      default: "chat-castle-icon.svg",
    },
    imgHeight: {
      type: String,
      default: "150",
    },
    imgWidth: {
      type: String,
      default: "150",
    },
  },
};
</script>
