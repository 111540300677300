<template>
  <div class="main-content-container">
    <BreadCrumb :items="['Special', 'Chungcheongbuk-do']" />
    <CardChatSpecial class="mb-3 mt-4" />
    <CardChatSpecial
      message="At the Doribangbang  Restaurant"
      :startCount="3"
      img="chat-special-food.svg"
      class="mb-3 mt-4"
    />
    <CardChatSpecial
      message="At the Doribangbang  Restaurant"
      :startCount="3"
      img="chat-special-question.svg"
      class="mb-3 mt-4"
    />
    <PostComment />
  </div>
</template>
<script>
import PostComment from "@/components/PostComment.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import CardChatSpecial from "@/components/Cards/CardChatSpecial.vue";
export default {
  components: {
    PostComment,
    BreadCrumb,
    CardChatSpecial,
  },
  setup() {},
};
</script>
