<script setup>
// import TextFieldSearch from "@/components/TextFieldSearch.vue";
import CardVideo from "@/components/Cards/CardVideo.vue";
import CardItemSmall from "@/components/Cards/CardItemSmall.vue";
import CardPost from "@/components/Cards/CardPost.vue";

// AIPRACTICE LIST
import { useAiPracticeStore } from "@/stores/aiPracticeStore";
const aiPracticeStore = useAiPracticeStore();
const { aiPracticeList, aiPracticeListLoading } = storeToRefs(aiPracticeStore);
const { fetchAIPracticeList, setAIPracticeQuery } = useAiPracticeStore();

aiPracticeList.value = [];
setAIPracticeQuery({
  limit: 4,
  order_by: "created_at",
  order: "desc",
  all: true,
  with_image: true,
  with_user: true,
  with_sub_category: true,
  is_bookmarked: true,
  for_client_home_page: true,
});

fetchAIPracticeList();

// POST LIST
import { useCommunityStore } from "@/stores/communityStore";
import { storeToRefs } from "pinia";
const communityStore = useCommunityStore();
const { communityList, communityListLoading } = storeToRefs(communityStore);
const { fetchCommunityList, setFetchQuery } = useCommunityStore();

communityList.value = [];
setFetchQuery({
  limit: 2,
  order_by: "created_at",
  order: "desc",
  all: true,
  with_images: true,
  is_bookmarked: true,
  with_user: true,
  for_client_home_page: true,
});

fetchCommunityList();

// VIDEO List
import { useVideoStore } from "@/stores/videoStore";
const videoStore = useVideoStore();
const { videoList, videoListLoading } = storeToRefs(videoStore);
const { fetchVideoList, setVideoListQuery } = useVideoStore();
setVideoListQuery({
  limit: 2,
  order_by: "created_at",
  order: "desc",
  all: true,
  with_image: true,
  with_category: true,
  is_bookmarked: true,
  for_client_home_page: true,
});
fetchVideoList();
</script>

<template>
  <div class="main-content-container">
    <!-- <TextFieldSearch class="py-3 px-3" /> -->
    <v-row v-if="videoListLoading && videoList.length == 0">
      <v-col cols="3">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="3">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
    <div v-if="videoList.length > 0">
      <h1 class="content-title">What’s Your Lesson?</h1>

      <v-row dense v-if="videoList && videoList.length > 0">
        <v-col cols="6" v-for="(video, index) in videoList" :key="index">
          <CardVideo
            :title="video.category?.name"
            :contentTitle="video?.title_kor"
            :contentImg="video.image?.path"
            :video="video"
          />
        </v-col>
      </v-row>
    </div>
    <h1 class="content-title">Fun Practice</h1>
    <v-row v-if="aiPracticeListLoading && aiPracticeList.length == 0">
      <v-col cols="6">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row dense v-if="aiPracticeList.length > 0">
      <v-col cols="3" v-for="(practice, index) in aiPracticeList" :key="index">
        <CardItemSmall
          :title="practice.title_en"
          :img="practice.image?.path"
          :practice="practice"
        />
      </v-col>
    </v-row>
    <h1 class="content-title">What’s New</h1>
    <v-row v-if="communityListLoading && communityList.length == 0">
      <v-col cols="6">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row dense v-if="communityList.length > 0">
      <v-col cols="6" v-for="(post, index) in communityList" :key="index">
        <CardPost
          :title="post.title"
          :subtitle="post.description"
          :userName="post.user?.name"
          :img="post.images && post.images[0] ? post.images[0].path : null"
          :post="post"
        />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.search {
  min-height: 43px;
}
</style>
