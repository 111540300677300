<style scoped>
.title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  color: #ff528a;
}
.posts-container {
  background: #f1e1c2;
  padding: 15px;
}
.post-item {
  text-align: left;
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
}
.post-time {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
}
</style>
<template>
  <div class="main-content-container mb-5">
    <div class="d-flex align-center justify-space-between mb-3">
      <p class="title">{{ postEdit ? 'Create Post' : 'My Post' }} </p>
      <div>
        <v-btn
					@click="postEdit = true"
          density="compact"
          icon="mdi-pencil"
          variant="text"
          color="#7D8CEF"
        >
        </v-btn>
        <v-btn
					@click="postEdit = true"
          class="ml-2"
          density="compact"
          icon="mdi-plus-circle"
          variant="text"
          color="#7D8CEF"
        >
        </v-btn>
      </div>
    </div>
    <div class="posts-container mb-3" v-if="!postEdit">
      <div class="post-item">
        <p>
          <v-avatar size="10" class="mr-3" color="#F18CA8"> </v-avatar>
          Please recommend a Korean cosmetics brand
        </p>
        <p class="post-time text-end">2024-03-05</p>
      </div>
      <div class="post-item">
        <p>
          <v-avatar size="10" class="mr-3" color="#F18CA8"> </v-avatar>
          Please recommend a Korean cosmetics brand
        </p>
        <p class="post-time text-end">2024-03-05</p>
      </div>
      <p class="text-center post-time mt-3">more....</p>
    </div>
    <div class="posts-container pa-3" v-if="postEdit">
      <v-textarea
        hide-details
        variant="solo"
        bg-color="white"
        class="elevation-0 mb-2"
        label="Label"
      ></v-textarea>
      <div class="d-flex align-center justify-space-between">
        <div>
          <v-btn
            density="compact"
            prepend-icon="mdi-file"
            variant="text"
            color="#EF3642"
          >
            File
          </v-btn>
          <v-btn
            density="compact"
            prepend-icon="mdi-account-supervisor"
            variant="text"
            color="#EF3642"
          >
            Tag
          </v-btn>
        </div>
        <v-btn @click="postEdit = false" density="compact" color="#EF3642" class="ml-2 elevation-0">
          <v-img
						
            class="mr-2"
            left
            height="12"
            width="12"
            src="@/assets/post-upload-icon.svg"
          />
          Publish
        </v-btn>
      </div>
    </div>
    <p class="title mb-4 mt-4">Save Post <v-icon> mdi-bookmark </v-icon></p>
    <CardItemCommunity class="mb-3" />
  </div>
</template>
<script setup>
	import { ref } from "vue";
	import CardItemCommunity from "@/components/Cards/CardPostMain.vue";
	const postEdit = ref(false);
</script>
