import request from '@/plugins/axios';
/**
 *
 * @param string url
 * @param object|array payload
 * @returns
 */
export function get(url, payload = {}) {
  return request.get(url, { params: payload});
}

/**
 *
 * @param string url
 * @param object|array payload
 * @returns
 */
export function post(url, payload) {
  return request({
    url: url,
    method: "POST",
    data: payload,
  });
}

/**
 *
 * @param string url
 * @param object|array payload
 * @returns
 */
export function put(url, payload) {
  return request({
    url: url,
    method: "PUT",
    data: payload,
  });
}

/**
 *
 * @param string url
 * @param object|array payload
 * @returns
 */
export function destroy(url, payload) {
  return request({
    url: url,
    method: "delete",
    data: payload,
  });
}

export function custom(config) {
  return request(config);
}
