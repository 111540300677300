<script setup>
import { defineProps } from "vue";
defineProps({
  isBot: {
    type: Boolean,
    default: false,
  },
  isUser: {
    type: Boolean,
    default: false,
  },
  message: {
    type: String,
    default: "",
  },
});

// USER STORE
import { useUserStore } from "@/stores/userStore";
const userStore = useUserStore();
</script>

<template>
  <div>
    <div class="d-flex align-center justify-end" v-if="isUser">
      <div class="message user-message">
        <div class="d-flex align-center user-icon">
          <v-img
            class="mr-2"
            width="30"
            height="30"
            src="@/assets/ai-user-icon.svg"
          />

          <p>
            {{ userStore.currentUser.name }}
          </p>
        </div>
        <p class="text-user" v-html="message"></p>
      </div>
    </div>

    <div class="message bot-message d-flex" v-if="isBot">
      <v-img
        class="bot-icon"
        height="60"
        width="60"
        src="@/assets/ai-chat-bot-icon.svg"
      />

      <div class="content-container">
        <p class="text mb-4" v-html="message"></p>
        <slot />
        <p class="ai-version mt-3">11.10 KOKO TALK</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.message {
  border-radius: 26px;
  padding: 40px 20px 20px 20px;
  position: relative;
}
/* BOT STYLE MESSAGE */
.bot-message {
  background: #e6e2fe;
  width: 295px;
}
.bot-icon {
  position: absolute;
  top: -25px;
  left: 10px;
}
.text {
  font-family: "SOYO Maple Bold", sans-serif;
  font-size: 16px;
  text-align: left;
  line-height: 23px;
}
.ai-version {
  font-size: 9px;
  text-align: right;
  line-height: 9px;
  color: grey;
}
.content-container {
  padding-right: 20px;
}
/* USER STYLE MESSAGE */
.user-message {
  background: #f1e1c2;
  width: 295px;
  padding-top: 50px;
}
.user-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
.text-user {
  font-family: "SOYO Maple Bold", sans-serif;
  font-size: 16px;
  text-align: left;
  line-height: 23px;
}
</style>
