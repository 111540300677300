<script setup>
import { onMounted, watch, ref, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";

const route = useRoute();
const router = useRouter();
// import CardVoiceQuestion from "@/components/Cards/CardVoiceQuestion.vue";

// AI PRACTICE STORE

import { useAiPracticeStore } from "@/stores/aiPracticeStore";
const aiPracticeStore = useAiPracticeStore();
const {
  AIConversationMission,
  AIPracticeDetail,
  AIPracticeDetailLoading,
  // CurrentMission,
  endOfMission,
	aiLoadingMessage,
} = storeToRefs(aiPracticeStore);
const {
  // handleAddStaticeConvo,
  InitilizeAI,
  fetchAIPracticeDetail,
  setAIPracticeQuery,
  handleUserMessage,
} = useAiPracticeStore();

const mainImage = ref();
const mainBG = ref();
let onlyMan = [6];

const setImage = () => {
  var ai_id = route.query.ai_practice_id;
  if (onlyMan.includes(Number(ai_id))) {
    mainImage.value = "practice-detail-man.svg";
    mainBG.value = "practice-detail-restaurant-bg.png";
  } else {
    mainImage.value = "chat-detail-woman.svg";
    mainBG.value = "chat-detail-main-cover.svg";
  }
};

onMounted(async () => {
  AIPracticeDetail.value = null;

  setAIPracticeQuery({
    id: route.query.id,
    single: true,
    with_missions: true,
    for_ai_practice_start_conversation_page: true,
  });
  elementToDisplayText.value = "";
  try {
    await fetchAIPracticeDetail(route.query.ai_practice_id).then(() => {
      AIConversationMission.value = AIPracticeDetail.value.missions;
    });
  } catch (error) {
    console.log(error);
  }
  setImage();
  await InitilizeAI();

	if(AIPracticeDetail.value && AIPracticeDetail.value.greetings.length > 0){
		const randomGreeting = AIPracticeDetail.value.greetings[Math.floor(Math.random() * AIPracticeDetail.value.greetings.length)];
		await AITextToSpeech(randomGreeting.message);
	}
});



// IMPORT VOICE INPUT
import { useVoiceInputStore } from "@/stores/voiceInputStore";
const voiceInputStore = useVoiceInputStore();
const { inputMessage, startRecording} = storeToRefs(voiceInputStore);
const { stopVoiceRecognition, stopRecording, handleVoiceClick } =
  useVoiceInputStore();
stopVoiceRecognition();
stopRecording();
watch(inputMessage, (newVal) => {
  if (!newVal) return;
  handleUserMessage(newVal);
  handleVoiceClick();
	aiLoadingMessage.value = true;
});

onUnmounted(() => {
	stopVoiceRecognition();
	stopRecording();
});

watch(endOfMission, (newVal) => {
  if (newVal) {
    stopRecording();
    stopVoiceRecognition();
    router.push({
      name: "practice-result",
      query: { ai_practice_id: route.query.ai_practice_id },
    });
  }
});

// OPEN AI STORE
import { useOpenAIStore } from "@/stores/openAIStore";
const openAiStore = useOpenAIStore();
const { elementToDisplayText, loadingAISpeech} = storeToRefs(openAiStore);
const { AITextToSpeech } = useOpenAIStore();

// MicroPhone
import VoiceBtn from "@/components/VoiceBtn.vue";

// HintDialog
import DialogHint from "@/components/Dialogs/DialogHint.vue";
const dialogHintShow = ref(false);

// Mission Dialog
import DialogMission from "@/components/Dialogs/DialogMission.vue";
const dialogMissionShow = ref(false);

// AI Conversation History Dialog
import DialogAICoversationHistory from "@/components/Dialogs/DialogAICoversationHistory.vue";
const dialogAICoversationHistoryShow = ref(false);
</script>

<template>
  <div class="main-content-container d-flex justify-center pa-0">
    <v-skeleton-loader
      class="w-100 px-5"
      v-if="AIPracticeDetailLoading"
      type="image,article"
    ></v-skeleton-loader>
    <div class="w-100" v-if="AIPracticeDetail && !AIPracticeDetailLoading">
      <DialogAICoversationHistory
        :dialog="dialogAICoversationHistoryShow"
        @dialog="dialogAICoversationHistoryShow = $event"
      />
      <v-btn
        @click="dialogAICoversationHistoryShow = true"
        color="warning"
        icon
        size="small"
        class="btn-meessage-history elevation-10"
      >
        <v-icon left>mdi-message-text-outline</v-icon>
      </v-btn>

      <v-img
        v-if="AIPracticeDetail"
        width="100%"
        height="100%"
        class=""
        cover
        :src="
          AIPracticeDetail.image_bg_conversation
            ? AIPracticeDetail.image_bg_conversation.path
            : mainBG
            ? require(`@/assets/${mainBG}`)
            : null
        "
      >
        <v-card
          height="100%"
          width="100%"
          class="main-container"
          color="transparent"
        >
          <DialogHint
            :dialog="dialogHintShow"
            @dialog="dialogHintShow = $event"
          />
          <DialogMission
            :dialog="dialogMissionShow"
            @dialog="dialogMissionShow = $event"
          />
          <div class="title d-flex align-center justify-center px-4">
						<span class="">
            {{ AIPracticeDetail.title_en }}
						</span>
          </div>
          <div class="icons-container text-right">
            <v-btn
              class="mr-2"
              icon
              size="40"
              variant="text"
              @click="dialogMissionShow = true"
            >
              <v-icon size="28" color="#49EE16"> mdi-check-circle </v-icon>
            </v-btn>
            <v-btn
              class=""
              icon
              size="40"
              variant="text"
              @click="dialogHintShow = true"
            >
              <v-icon size="28" color="yellow"> mdi-lightbulb-on </v-icon>
            </v-btn>
          </div>
          <div
            class="main-content-data d-flex align-center justify-space-between flex-column"
          >
            <div class="w-100 text-center d-flex align-center justify-center">
              <div
                class="ai-text custom-scroll-bar mb-2 text-center pa-3 rounded-lg"
                v-if="loadingAISpeech || elementToDisplayText" 
              >
                <v-icon
                  size="24"
                  :class="`${loadingAISpeech ? 'icon-tilt' : ''}`"
                  color="white"
                >
                  {{
                    loadingAISpeech
                      ? "mdi-robot-happy-outline"
                      : "mdi-robot-outline"
                  }}
                </v-icon>

                <p v-if="elementToDisplayText">{{ elementToDisplayText }}</p>
              </div>
            </div>

            <div class="voice-container d-flex flex-column align-center">
              <v-chip size="small" class="mb-2 elevation-0 chip-container">
                {{ startRecording ? "Start Speaking" : "Muted" }}
              </v-chip>
              <VoiceBtn
                :disabled="loadingAISpeech || aiLoadingMessage"
                class="d-block mx-auto mt-2"
              />
              <br />
              <v-btn
                @click="
                  $router.push({
                    name: 'practice-result',
                    query: { ai_practice_id: AIPracticeDetail.id },
                  })
                "
                prepend-icon="mdi-phone-hangup"
                color="white"
                class="mt-5 rounded-pill elevation-5"
                density="comfortable"
              >
                End Conversation
              </v-btn>
            </div>
          </div>
          <v-img
            v-if="mainImage"
            class="img-highlight"
            height="500"
            width="100%"
            contain
            :src="
              AIPracticeDetail.image_front_conversation
                ? AIPracticeDetail.image_front_conversation.path
                : mainImage
                ? require(`@/assets/${mainImage}`)
                : null
            "
          >
          </v-img>
        </v-card>
      </v-img>
    </div>
    <div v-if="!AIPracticeDetail && !AIPracticeDetailLoading" class="py-5">
      <no-data-found />
      <v-btn
        class="mt-5 rounded-pill"
        color="primary"
        @click="$router.push({ name: 'home' })"
      >
        Home
      </v-btn>
    </div>
  </div>
</template>

<style scoped>
.controls-container {
  position: absolute;
  bottom: 0;
  height: 100%;
}
.voice-container {
  margin-bottom: 80px;
}
.main-content-data {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
	padding-top: 250px;
  overflow: hidden;
}
.chip-container {
  background: rgba(147, 147, 147, 0.5);
  color: white;
}
.ai-text {
  position: relative;
  color: white;
  min-width: 150px;
  max-height: 200px;
  max-width: 90%;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.7);
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.custom-scroll-bar::-webkit-scrollbar-track {
  background-color: transparent;
}
.custom-scroll-bar::-webkit-scrollbar {
  width: 20px;
}
.main-content-container {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  overflow: hidden !important;
  min-height: 100px;
  min-height: 700px;
  height: 100vh;
  position: relative;
}

.title {
  position: absolute;
  left: 0;
  top: 0;
	padding-top: 50px;
  display: block;
  font-family: "SOYO Maple Bold";
  font-size: 36px;
  width: 100% !important;
  font-weight: 600;
  line-height: 43.57px;
  text-align: center;
  color: white;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 0px !important;
  height: auto;
  z-index: 1; background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.icons-container {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 45px;
  width: 130px;
  z-index: 5;
}
.img-highlight {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

/* @media only screen and (max-width: 786px) {
  .img-highlight {
    width: 300px !important;
    height: 500px !important;
  }
} */

.icon-tilt {
  animation: tilt-shaking 0.3s infinite;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.btn-meessage-history {
  position: absolute;
  bottom: 100px;
  right: 10px;
  z-index: 5;
}

/* Mobile Layout Styles */
/* @media only screen and (max-width: 360px) {
  .voice-container {
    margin-bottom: 270px;
  }
} */
</style>
