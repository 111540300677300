import { defineStore } from "pinia";
import { ref } from "vue";
import * as api from "@/core/api";

import { useUserStore } from "@/stores/userStore";

export const useUserProgressStore = defineStore("userProgressStore", () => {
  // USER
  const userStore = useUserStore();
	const USER = userStore.currentUser;
	
  const userProgressLoading = ref(false);
  const userProgressQuery = ref({});
  const setUserProgressQuery = (query) => {
    userProgressQuery.value = query;
  };
  const fetchUserProgress = async () => {
    userProgressLoading.value = true;
    let res = null;
    await api
      .get("/user-progress", userProgressQuery.value)
      .then((response) => {
        res = response.data;
      })
      .finally(() => {
        userProgressLoading.value = false;
      });
    return res;
  };
  const handleUserProgress = async (mission) => {
    if (!mission.id) return;
    let res = null;
    const data = {
      user_id: USER.id,
      mission_id: mission.id,
      status: "completed",
    };

    await api.post("/user-progress", data).then((response) => {
      res = response.data;
    });
    return res;
  };

  return {
    userProgressLoading,
    userProgressQuery,
    setUserProgressQuery,
    fetchUserProgress,
    handleUserProgress,
  };
});
