import { defineStore } from "pinia";
import { useUserStore } from "./userStore";
import { ref } from "vue";

import * as api from "@/core/api";

/**
 * @TODO This is only for mocking data and should be removed in production
 * This is for demo purposes only
 */

export const useAuthStore = defineStore("authStore", () => {
  const userStore = useUserStore();

  /**
   * state
   */
  const formData = ref({
    email: "admin@kokotalk.com",
    password: "password",
  });

  const formSignup = ref({
    username: null,
    email: null,
    password: null,
    confirm_password: null,
    interests: null,
  });

  const showPassword = ref(false);
  const showConfirmPassword = ref(false);
  const loading = ref(false);

  const incorrectCredentials = ref(false);
  const validateSignup = ref(null);
  const validateLogin = ref(null);

  const rules = ref({
    email: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    password: [
      (v) => !!v || "Password is required",
      (v) => v.length >= 8 || "Password must be at least 8 characters",
    ],
    confirm_password: [
      (v) => !!v || "Confirm Password is required",
      (v) => v === formSignup.value.password || "Password does not match",
    ],
    username: [
      (v) => !!v || "Username is required",
      (v) => v.length >= 3 || "Username must be at least 3 characters",
    ],
    interests: [(v) => !!v || "Interests is required"],
  });

  /**
   * getters
   */

  /**
   * actions
   */

  const login = async () => {
    incorrectCredentials.value = false;
    loading.value = true;

    try {
      await api
        .post("/login", {
          email: formData.value.email,
          password: formData.value.password,
          with_image: true,
        })
        .then(({ data }) => {
          console.log(data, "ora");
          userStore.setCurrentUser(data.userData);
          userStore.setToken(data.accessToken);
          clearForm();
        })
        .finally(() => {
          loading.value = false;
        });
    } catch (error) {
      incorrectCredentials.value = true;
      return false;
    }
    return true;
  };

  const signUp = async () => {
    loading.value = true;
    try {
      await api
        .post("/register", {
          email: formSignup.value.email,
          password: formSignup.value.password,
          name: formSignup.value.username,
          // interests: formSignup.value.interests,
          confirm_password: formSignup.value.confirm_password,
        })
        .finally(() => {
          loading.value = false;
        });
      clearForm();
    } catch (error) {
      console.error(error);
      return false;
    }
    return true;
  };

  const logout = async () => {
    await userStore.setCurrentUser(null);
    await userStore.clearToken();
    localStorage.setItem('userStore', null)
  };

  const clearForm = () => {
    // Login Form
    formData.value.email = null;
    formData.value.password = null;

    // Signup Form
    formSignup.value.email = null;
    formSignup.value.password = null;
    formSignup.value.confirm_password = null;
    formSignup.value.interests = null;
    formSignup.value.username = null;
  };
  return {
    formData,
    formSignup,
    showPassword,
    showConfirmPassword,
    loading,
    incorrectCredentials,
    validateSignup,
    validateLogin,
    rules,
    login,
    logout,
    signUp,
    clearForm,
  };
});
