import { defineStore } from "pinia";
import { ref } from "vue";

export const usePersistantStore = defineStore("persistantStore", () => {
	const selected_subscription_id = ref(false);

	return {
    selected_subscription_id,
  };
}, {
	persist : true,
})