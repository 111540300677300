<script setup>
import { useRoute } from "vue-router";
import { watch, ref } from "vue";
const route = useRoute();
const active = ref("");
const container = ref(null);
watch(
  () => route,
  (val) => {
    if (val && val.meta && val.meta.tab) {
      active.value = val.meta.tab;
    } else {
      active.value = "";
    }
  },
  { deep: true, immediate: true }
);
</script>
<template>
  <div
    ref="container"
    class="footer-container d-flex align-center justify-space-around"
    v-if="active"
  >
    <div class="w-100 h-100 d-flex align-center justify-center">
      <v-btn
        @click="$router.push({ name: 'home' })"
        block
        variant="text"
        height="100%"
        class="rounded-0"
				:active="active == 'home'"
      >
        <v-img
          height="24"
          width="40"
          :src="require(`@/assets/footer-home${ active == 'home' ? '-active' : '' }-icon.svg`)"
        />
      </v-btn>
    </div>
    <div class="w-100 h-100 d-flex align-center justify-center">
      <v-btn
        height="100%"
        @click="$router.push({ name: 'video' })"
        block
        variant="text"
        class="rounded-0"
				:active="active == 'video'"
      >
        <v-img
          height="24"
          width="40"
          :src="
            require(`@/assets/footer-play${
              active == 'play' ? '-active' : ''
            }-icon.svg`)
          "
        />
      </v-btn>
    </div>
    <div class="w-100 h-100 d-flex align-center justify-center">
      <v-btn
        @click="$router.push({ name: 'category' })"
        block
        variant="text"
        height="100%"
        class="rounded-0"
				:active="active == 'category'"
      >
        <v-img
          height="24"
          width="40"
          :src="
            require(`@/assets/footer-chat${
              active == 'chat' ? '-active' : ''
            }-icon.svg`)
          "
        />
      </v-btn>
    </div>
    <div class="w-100 h-100 d-flex align-center justify-center">
      <v-btn
        class="rounded-0"
        @click="$router.push({ name: 'community' })"
        height="100%"
        block
				:active="active == 'community'"
        variant="text"
      >
        <v-img
          height="24"
          width="40"
          :src="
            require(`@/assets/footer-heart${
              active == 'heart' ? '-active' : ''
            }-icon.svg`)
          "
        />
      </v-btn>
    </div>
    <div class="w-100 h-100 d-flex align-center justify-center">
      <v-btn
        @click="$router.push({ name: 'ai-chat' })"
        height="100%"
        block
				:active="active == 'robot'"
        class="rounded-0"
        variant="text"
      >
        <v-img
          height="24"
          width="40"
          :src="
            require(`@/assets/footer-robot${
              active == 'robot' ? '-active' : ''
            }-icon.svg`)
          "
        />
      </v-btn>
    </div>
  </div>
</template>


<style scoped>
/* SOME CSS CAN BE FOUND IN GLOBAL.CSS */
.footer-container {
  display: block;
  background-color: #688eef;
  height: 62px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 100;
}
.active {
  height: 26px !important;
  color: red !important;
}
.image {
  height: 24px;
  width: 40px;
  background-size: cover;
}
</style>