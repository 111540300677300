import { defineStore } from "pinia";
import { ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import * as api from "@/core/api";

export const useBookmarkStore = defineStore("bookmarkStore", () => {
  const userStore = useUserStore();
  const USER = userStore.getCurrentUser;
  const handleBookark = async (model = false, model_id = false) => {
    if (!model || !model_id) return;
    var res = false;

    const form = {
      bookmarkable_type: model,
      bookmarkable_id: model_id,
      user_id: USER.id,
    };

    await api
      .post(`/bookmarks`, form)
      .then((response) => {
        res = response;
        console.log("Bookmark added:", res);
        fetchBookmarkList();
      })
      .catch((error) => {
        console.log("bookmark error: ", error);
      })
      .finally(() => {});

    return res;
  };

  const handleDeleteBookmark = async (id = false) => {
    if (!id) return;
    var res = false;
    await api
      .destroy(`/bookmarks/${id}`)
      .then((response) => {
        res = response;
        fetchBookmarkList();
      })
      .finally(() => {});

    return res;
  };

  const bookMarkList = ref([]);
  const bookMarkListLoading = ref(false);
  const bookMarkQuery = ref({});

  const setBookmarkQuery = (query) => {
    bookMarkQuery.value = query;
  };

  const fetchBookmarkList = async () => {
    bookMarkListLoading.value = true;
    let res = null;
    await api
      .get(`/bookmarks`, bookMarkQuery.value)
      .then((response) => {
        bookMarkList.value = response.data;
        res = response;
      })
      .finally(() => {
        bookMarkListLoading.value = false;
      });

    return res;
  };

  return {
    handleBookark,
    handleDeleteBookmark,

    bookMarkList,
    bookMarkListLoading,
    bookMarkQuery,
    setBookmarkQuery,
    fetchBookmarkList,
  };
});
