<script setup>
import { storeToRefs } from "pinia";

// PAYMENT STORE
import { usePaymentStore } from "@/stores/paymentStore";
const paymentStore = usePaymentStore();
const { paymentList, paymentListLoading } = storeToRefs(paymentStore);
const { setPaymentListQuery, fetchPaymentList } = usePaymentStore();

import { useUserStore } from "@/stores/userStore";
const userStore = useUserStore();
const USER = userStore.getCurrentUser;
setPaymentListQuery({
  with_user: true,
  user_id: USER.id,
  order_by: "created_at",
  order: "desc",
});

fetchPaymentList();
</script>
<template>
  <div class="main-content-container text-left pt-4 px-5">
    <h3 class="text-left">Payment History</h3>
    <v-list v-if="paymentListLoading && paymentList.length == 0">
      <v-list-item>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-list-item>
			<v-list-item>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-list-item>
    </v-list>
    <v-list three-line v-if="paymentList.length > 0">
      <v-list-item v-for="(payment, index) in paymentList" :key="index">
        <v-list-item-content>
          <v-list-item-title>{{ payment.subscription_id == 1 ? 'Monthly' : 'Yearly' }} - <i> {{ payment.amount }} </i> </v-list-item-title>
          <v-list-item-subtitle>{{ payment.created_at }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
		<p class="text-center py-5 text-caption" v-if="paymentList == 0 && !paymentListLoading"> No Data Found </p>
  </div>
</template>
