<style scoped>
.title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: center;
}
.mission-container {
  border: 1px solid #59dff7;
  border-radius: 24px;
  padding: 11px;
}
.mission-content {
  background: white;
  border-radius: 24px;
}
.text {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
}
.action-button {
  background: #ff528a;
  color: white;
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: center;
  height: 45px;
}
</style>
<template>
  <div class="main-content-container mb-5">
    <div>
      <div v-if="cableCar">
        <p class="title" v-html="title"></p>
        <v-img
          class="mt-4 mx-2"
          src="@/assets/chat-special-detail-cable-car.svg"
					aspect-ratio="16/9"
					width="100%"

        />
        <div class="mission-container mt-5 mb-5 d-flex align-center justify-center" :style="`background : ${bgColor}`">
          <div class="mission-content w-100">
            <p class="my-6 text pt-5">
              <v-icon color="#75F94C">mdi-check-circle</v-icon> Mission <br />
              Asking a small size <br />
              Asking a blue skirt
            </p>
						<v-btn
            width="137"
            rounded="pill"
            height="45"
            class="action-button elevation-0 mb-5"
            @click="$router.push({ name: 'ai-practice-conversation' })"
          >
            START
          </v-btn>
          </div>
        </div>
      </div>
      <div
        v-else
        class="mission-container mt-5 mb-5"
        :style="`background : ${bgColor}`"
      >
        <p class="title mb-4 text-white" v-html="title"></p>
        <div class="mission-content text-center">
          <div class="d-flex align-center justify-center mt-5" v-if="bodyImg">
            <v-img
              width="125"
              height="125"
							class="mt-5"
              :src="require(`@/assets/${bodyImg}`)"
            />
          </div>
          <p class="my-6 text pt-5">
            <v-icon color="#75F94C">mdi-check-circle</v-icon> Mission <br />
            Asking a small size <br />
            Asking a blue skirt
          </p>
          <v-btn
            width="137"
            rounded="pill"
            height="45"
            class="action-button elevation-0 mb-5"
            @click="$router.push({ name: 'ai-practice-conversation' })"
          >
            START
          </v-btn>
        </div>
      </div>
      <CardChatSpecialRecommended class="mb-5" v-if="recommend" />
      <CardChatSpecialReview class="mt-5" v-if="special" />
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import CardChatSpecialRecommended from "@/components/Cards/CardChatSpecialRecommended.vue";
import CardChatSpecialReview from "@/components/Cards/CardChatSpecialReview.vue";
import { useRoute } from "vue-router";
const route = useRoute();

const recommend = ref(false);
const special = ref(false);
const cableCar = ref(true);
const title = ref("");
const bgColor = ref("#c4f6ff");
const bodyImg = ref("");

if (route && route.query) {
  if (route.query.title) title.value = route.query.title;
  if (route.query.hideCableCar) cableCar.value = false;
  if (route.query.bgColor) bgColor.value = route.query.bgColor;
  if (route.query.recommend) recommend.value = true;
  if (route.query.special) special.value = true;
  if (route.query.bodyImg) bodyImg.value = route.query.bodyImg;
}
</script>
