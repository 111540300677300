<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
const id = route.query.id;

// AI PRACTICE STORE
import { useAiPracticeStore } from "@/stores/aiPracticeStore";
import { storeToRefs } from "pinia";
const aiPracticeStore = useAiPracticeStore();
const { AIPracticeDetail, AIPracticeDetailLoading } =
  storeToRefs(aiPracticeStore);
const { fetchAIPracticeList, setAIPracticeQuery } = useAiPracticeStore();
const loading = ref(false);
onMounted(async () => {
  loading.value = true;
  AIPracticeDetail.value = null;

  setAIPracticeQuery({
    id: id,
    single: true,
    with_image: true,
    with_videos: true,
    with_videos_image: true,
    with_missions: true,
    for_ai_practice_start_page: true,
  });

  await fetchAIPracticeList().then((res) => {
    if (res) {
      AIPracticeDetail.value = res;
    }
    loading.value = false;
  });
});

import CardRecomentedVideo from "@/components/Cards/CardRecommendedVideo.vue";

// YOUTUBE PLAYER
import YoutubePlayer from "@/components/Others/YoutubePlayer.vue";
</script>

<template>
  <div class="main-content-container mb-5">
    <v-progress-circular
      v-if="loading && !AIPracticeDetail"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <div
      v-if="AIPracticeDetail"
      class="container-content pa-4 rounded-xl text-white"
    >
      <p class="title" v-html="AIPracticeDetail.title_en"></p>
      <div class="mt-4 mx-2 text-center d-flex align-center justify-center">
        <div class="w-100">
          <VCard
            min-height="150"
            min-width="300"
            max-height="350"
            class="player"
            v-if="AIPracticeDetail.video_url"
          >
            <YoutubePlayer :id="AIPracticeDetail.video_url" />
          </VCard>
          <v-img
            v-else-if="AIPracticeDetail.image"
            contain
            class="mx-auto"
            :src="AIPracticeDetail?.image?.path"
            height="250"
            width="250"
          />
        </div>
      </div>
      <div class="mission-container d-flex align-center justify-center">
        <div class="mission-content w-100 text-center">
          <v-card
            color="transparent"
            variant="flat"
            class="text mx-auto"
            width="250"
            v-if="AIPracticeDetail?.missions?.length > 0"
          >
            <p class="d-flex align-center mx-auto mb-2 justify-center">
              <v-icon color="#75F94C" class="mr-1">mdi-check-circle</v-icon>
              Mission
            </p>
            <div
              class="mb-0 pb-0"
              v-for="(mission, index) in AIPracticeDetail?.missions"
              :key="index"
            >
              {{ mission.title_en }}
            </div>
          </v-card>
          <v-btn
            width="180"
            height="25"
            rounded="pill"
            class="action-button elevation-5 mt-4"
            @click="
              $router.push({
                name: 'ai-practice-conversation',
                query: { ai_practice_id: AIPracticeDetail.id },
              })
            "
          >
            START <v-icon>mdi-play</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div
      v-if="
        AIPracticeDetail &&
        AIPracticeDetail.videos &&
        AIPracticeDetail.videos.length > 0
      "
      class="mb-5"
    >
      <p class="content-title mb-4">Recommend Lessons</p>
      <v-row class="">
        <v-col
          cols="4"
          lg="4"
          md="4"
          sm="4"
          v-for="(video, index) in AIPracticeDetail.videos"
          :key="index"
        >
          <CardRecomentedVideo
            @click="
              $router.push({
                name: 'video-lesson',
                query: { ai_conversation_id: AIPracticeDetail.id },
              })
            "
            :title="video.title_en"
            :img="video?.image?.path"
          />
        </v-col>
      </v-row>
    </div>
    <br />
    <v-skeleton-loader
      v-if="AIPracticeDetailLoading && !AIPracticeDetail"
      type="image, article"
    ></v-skeleton-loader>
  </div>
</template>
<style scoped>
.container-content {
  background: #280942;
}
.main-content-container {
  min-height: 100vh;
  height: 100%;
}
.title {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: center;
}
.mission-container {
  border-radius: 24px;
  padding: 11px;
  max-width: 500px;
  margin: 0 auto;
}
.mission-content {
  border-radius: 24px;
}
.text {
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
}
.action-button {
  background: #ff528a;
  color: white;
  font-family: "SOYO Maple Bold", Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
</style>
<style>
.yt-lite {
  min-height: 300px;
}
</style>
