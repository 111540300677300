import { defineStore } from "pinia";
import { ref } from "vue";
import OpenAI from "openai";
export const useOpenAIStore = defineStore(
  "openAIStore",
  () => {
		const openai = ref(null);

    const loadingAISpeech = ref(false);
    const elementToDisplayText = ref();

    const initializeOpenAI = async () => {
      openai.value = new OpenAI({
        apiKey: process.env.VUE_APP_OPENAI_KEY,
        dangerouslyAllowBrowser: true,
      });
    };
		const promise = ref(null);
    const AITextToSpeech = async (message, return_audio = false) => {
      if (!message) return;
      if (!openai.value) await initializeOpenAI();
			loadingAISpeech.value = true;
      var response = await openai.value.audio.speech.create({
        input: message,
        model: "tts-1",
        voice: "alloy",
      });

      // Convert the response to a blob (binary audio file)
      const audioBlob = await response.blob();

      // Create a URL for the audio blob
      const audioUrl = URL.createObjectURL(audioBlob);

      if (return_audio) return audioUrl;

      // Create an Audio object and set the source to the audio URL
      const audio = new Audio(audioUrl);

      // Play the audio
      audio.play();

      audio.onloadedmetadata = () => {
				handleTextComputation(audio.duration, message);
				elementToDisplayText.value = "";
      };

      // add on time update event listener
      audio.ontimeupdate = () => {
        handleTextToDisplayWhilePlaying(audio.currentTime);
      };

      audio.onended = () => {
        loadingAISpeech.value = false;
        promise.value(true);
      };

      return new Promise((resolve) => {
        promise.value = resolve;
      });
    };

    const thread = ref(null);
    const createOpenAIThread = async () => {
      if (!openai.value) await initializeOpenAI();
      thread.value = await openai.value.beta.threads.create();
    };
    const currentSecond = ref(null);
    const textArray = ref([]);
    const handleTextToDisplayWhilePlaying = (currentTime) => {
      // get second only
      const second = Math.ceil(currentTime);
      const currentIndex = second - 1;
      if (currentSecond.value !== currentIndex) {
        currentSecond.value = currentIndex;
        if (textArray.value[currentIndex])
          elementToDisplayText.value += `${textArray.value[currentIndex]}`;
      }
    };

    const handleTextComputation = (duration, text) => {
      const textLength = text.length;
      const textSplit = Math.ceil(textLength / duration);
      var compile = [];
      for (let i = 0; i < textLength; i += textSplit) {
        compile.push(text.slice(i, i + textSplit));
      }
      textArray.value = compile;
    };

    return {
      openai,
      thread,
      loadingAISpeech,
      initializeOpenAI,
      createOpenAIThread,

      // TEXT TO SPEECH
      AITextToSpeech,
      handleTextToDisplayWhilePlaying,
      elementToDisplayText,
      textArray,
      currentSecond,
    };
  },
  { persist: true }
);
