<style>
	.breadcrumb {
		font-family: "SOYO Maple Bold";
		font-size: 12px;
		font-weight: 500;
		line-height: 14.52px;
		text-align: left;

	}
	.breadcrumb-item {
		color: #000000;
	}
</style>
<template>
	<p class="breadcrumb">
		<span v-for="(level, index) in items" :key="index" class="breadcrumb">
			<span class="breadcrumb-item">{{ level }}</span>
			<span v-if="index < items.length - 1"> > </span>
		</span>
	</p>
</template>

<script>
export default {
	props : {
		items: {
			type: Array,
			default: () => ['Travel', 'Restaurant'],
		}
	},
	setup() {
	}
}
</script>
