<script setup>
import { defineProps, ref } from "vue";
import { useBookmarkStore } from "@/stores/bookmarkStore";

const props = defineProps({
  bookmark: {
    type: Object,
    default: () => {},
  },
  bgColor: {
    type: String,
    default: "#C4F6FF",
  },
  borderColor: {
    type: String,
    default: "#59DFF7",
  },
  title: {
    type: String,
    default: "Travel",
  },
  img: {
    type: String,
    default: "gimbap-icon.svg",
  },
  contentImgHeight: {
    type: String,
    default: "90",
  },
  contentImgWidth: {
    type: String,
    default: "75",
  },
  subtitle: {
    type: String,
    default: null,
  },
  userProfile: {
    type: String,
    default: "community-user-dog.svg",
  },
  userName: {
    type: String,
    default: "Clara",
  },
  model: {
    type: String,
    default: "posts",
  },
  model_id: {
    type: Number,
    default: null,
  },
  id: {
    type: Number,
    default: null,
  },
});

const bookmark_id = ref(props.bookmark.id);
const loadingBookmark = ref(false);

const { handleDeleteBookmark } = useBookmarkStore();

const handleBookmark = async () => {
  loadingBookmark.value = true;
  if (bookmark_id.value) {
    await handleDeleteBookmark(bookmark_id.value).then(() => {
      bookmark_id.value = null;
      location.reload();
    });
  }
  loadingBookmark.value = false;
};
</script>

<template>
  <div
    class="container"
    :style="`background : ${bgColor}; border: 1px solid ${borderColor}`"
  >
    <div class="d-flex justify-space-between align-center ps-4">
      <p class="title text-left text-truncate" v-html="title"></p>
      <v-btn
        @click="handleBookmark"
        :loading="loadingBookmark"
        icon="true"
        variant="icon"
        size="24"
        class="mr-2 elevation-0"
        @updateBookmark="updateBookmarkStatus"
      >
        <v-icon color="#FF6666">mdi-bookmark</v-icon>
      </v-btn>
    </div>
    <div class="px-2 pb-2" @click="$router.push({ name: 'community' })">
      <div class="d-flex flex-column justify-space-between">
        <div class="">
          <v-tooltip
            width="200"
            v-if="subtitle"
            location="top"
            :text="subtitle"
          >
            <template v-slot:activator="{ props }">
              <p
                v-bind="props"
                class="subtitle text-left my-1 d-block text-truncate"
                style="max-width: 100%"
              >
                {{ subtitle }}
              </p>
            </template>
          </v-tooltip>
        </div>
        <div class="">
          <v-img
            cover
            class="rounded-lg"
            height="100"
            width="100%"
            :src="img"
          />
          <div class="d-flex align-center mt-2">
            <div>
              <v-img
                class="user-icon"
                height="24"
                width="24"
                :src="require(`@/assets/${userProfile}`)"
              />
            </div>
            <span class="user pl-1"> {{ userName }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.post-content {
  width: 50%;
}
.container {
  height: 190px;
  border-radius: 26px 0px 26px 26px;
  position: relative;
  cursor: pointer;
}
.bookmark-icon {
  position: absolute;
  top: -2px;
  right: -2px;
}
.title {
  font-family: "SOYO Maple Bold";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
}

.subtitle {
  font-family: "SOYO Maple Bold";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
}
.user {
  font-family: "SOYO Maple Bold";
  font-size: 16px;
  font-weight: 600;
  color: #4f4f4f;
  padding-bottom: 7px;
}
.social-react {
  position: absolute;
  top: 0;
  right: 0;
}
.image-container {
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .title {
    padding-top: 10px;
    font-size: 13px;
    line-height: 14px;
  }
  .content-image {
    height: 60px !important;
    width: 80px !important;
  }
  .image-container {
    align-items: center !important;
  }
  .user {
    font-size: 12px;
  }
  .user-icon {
    height: 24px !important;
    width: 24px !important;
  }
}
</style>
