import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import SignupView from "../views/SignupView.vue";
import VideoView from "../views/VideoView.vue";
import VideoDetailView from "../views/VideoDetailView.vue";
import ChatCategoryView from "../views/ChatCategoryView.vue";
import AIPracticeConversationView from "../views/AIPracticeConversationView.vue";
import ChatCategorySpecialView from "../views/ChatSpecialView.vue";
import ChatCategorySpecialDetailView from "../views/ChatSpecialDetailView.vue";
import CommunityView from "../views/CommunityView.vue";
import AIChatView from "../views/AIChatView.vue";
import ProfileView from "../views/ProfileView.vue";
import ProfileEditView from "../views/ProfileEditView.vue";
import PostView from "../views/PostView.vue";
import PracticeResultView from "../views/AIPracticeResultView.vue";
import { useUserStore } from "../stores/userStore";
import IntroView from "../views/IntroView.vue";
import SettingView from "../views/SettingView.vue";
import SubscriptionView from "../views/SubscriptionView.vue";
import CategoryView from "../views/CategoryView.vue";
import SubCategoryView from "../views/SubCategoryView.vue";
import AIPracticeListView from "../views/AIPracticeListView.vue";
import AIPracticeView from "../views/AIPracticeView.vue";
import VideoLessonView from "../views/VideoLessonView.vue";
import PostFormView from "../views/PostFormView.vue";
import FunPracticeView from "../views/FunPracticeView.vue";
import SpecialView from "../views/SpecialView.vue";
import PaymentSuccessView from "../views/PaymentSuccessView.vue";
import PaymentListView from "../views/PaymentListView.vue";

const routes = [
  {
    path: "/",
    redirect: (to) => {
      if (to && to.meta && to.meta.requiresAuth) {
        const userStore = useUserStore();
        const USER = userStore.currentUser;
        if (USER) {
          return { name: "home", query: to.query };
        }
        return { name: "intro" };
      }
      return { name: "intro" };
    },
  },
  {
    path: "/intro",
    name: "intro",
    component: IntroView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },

  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/signup",
    name: "signup",
    component: SignupView,
    meta: {
      bg: "bg-signup",
    },
  },
  {
    path: "/home",
    name: "home",
    meta: {
      tab: "home",
      requiresAuth: true,
    },
    component: HomeView,
  },
  {
    path: "/video",
    name: "video",
    component: VideoView,
    meta: {
      tab: "play",
      requiresAuth: true,
    },
  },
  {
    path: "/video-detail",
    name: "video-detail",
    meta: {
      tab: "play",
      requiresAuth: true,
    },
    component: VideoDetailView,
  },
  {
    path: "/chat-category",
    name: "chat-category",
    component: ChatCategoryView,
    meta: {
      tab: "chat",
      requiresAuth: true,
    },
  },
  {
    path: "/ai-practice-conversation",
    name: "ai-practice-conversation",
    component: AIPracticeConversationView,
    meta: {
      tab: "chat",
      requiresAuth: true,
    },
  },
  {
    path: "/practice-result",
    name: "practice-result",
    component: PracticeResultView,
    meta: {
      tab: "chat",
      requiresAuth: true,
    },
  },
  {
    path: "/community",
    name: "community",
    component: CommunityView,
    meta: {
      tab: "heart",
      requiresAuth: true,
    },
  },
  {
    path: "/category",
    name: "category",
    component: CategoryView,
    meta: {
      tab: "chat",
      requiresAuth: true,
    },
  },
  {
    path: "/sub-category",
    name: "sub-category",
    component: SubCategoryView,
    meta: {
      tab: "chat",
      requiresAuth: true,
    },
  },
  {
    path: "/ai-practice-list",
    name: "ai-practice-list",
    component: AIPracticeListView,
    meta: {
      tab: "chat",
      requiresAuth: true,
    },
  },
  {
    path: "/ai-practice",
    name: "ai-practice",
    component: AIPracticeView,
    meta: {
      tab: "chat",
      requiresAuth: true,
    },
  },
  {
    path: "/video-lesson",
    name: "video-lesson",
    component: VideoLessonView,
    meta: {
      tab: "chat",
      requiresAuth: true,
    },
  },
  {
    path: "/post-form",
    name: "post-form",
    component: PostFormView,
    meta: {
      tab: "heart",
      requiresAuth: true,
    },
  },
  {
    path: "/fun-practice",
    name: "fun-practice",
    component: FunPracticeView,
    meta: {
      tab: "heart",
      requiresAuth: true,
    },
  },
  {
    path: "/specials",
    name: "specials",
    component: SpecialView,
    meta: {
      tab: "chat",
      requiresAuth: true,
    },
  },
  {
    path: "/ai-chat",
    name: "ai-chat",
    component: AIChatView,
    meta: {
      tab: "robot",
      requiresAuth: true,
    },
  },
  {
    path: "/chat-special",
    name: "chat-special",
    component: ChatCategorySpecialView,
    meta: {
      tab: "chat",
      requiresAuth: true,
    },
  },
  {
    path: "/chat-special-detail",
    name: "chat-special-detail",
    component: ChatCategorySpecialDetailView,
    meta: {
      tab: "chat",
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: {
      tab: "home",
      requiresAuth: true,
    },
  },
  {
    path: "/post",
    name: "post",
    component: PostView,
    meta: {
      tab: "heart",
      requiresAuth: true,
    },
  },
  {
    path: "/profile-edit",
    name: "profile-edit",
    component: ProfileEditView,
    meta: {
      tab: "home",
      requiresAuth: true,
    },
  },
  {
    path: "/setting",
    name: "setting",
    component: SettingView,
    meta: {
      tab: "home",
      requiresAuth: true,
    },
  },
  {
    path: "/subscription",
    name: "subscription",
    component: SubscriptionView,
    meta: {
      tab: "home",
      requiresAuth: true,
    },
  },
  {
    path: "/payment-success",
    name: "payment-success",
    component: PaymentSuccessView,
    meta: {
      tab: "home",
      requiresAuth: true,
    },
  },
  {
    path: "/payment-list",
    name: "payment-list",
    component: PaymentListView,
    meta: {
      tab: "home",
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/", // this is temporary
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const USER = userStore.currentUser;
  if (to && to.meta.requiresAuth) {
    if (!userStore.currentUser) next("/login");
    else next();
  }
  if (USER) next({ name: "home" });
  else next();
});

export default router;
