import { defineStore } from "pinia";
import { ref } from "vue";

import * as api from "@/core/api";
import { useUserStore } from "./userStore";

export const useCommentStore = defineStore(
  "commentStore",
  () => {
    const userStore = useUserStore();
    const USER = userStore.currentUser;
    const commentList = ref([]); // generate sample comment data with the following data structure
    const commentListLoading = ref(false);
    const commentQuery = ref({});

    const setCommentFetchQuery = (query) => {
      commentQuery.value = query;
    };

    const fetchCommentList = async () => {
      commentListLoading.value = true;
      let res = null;
      await api
        .get("/comments", commentQuery.value)
        .then((response) => {
          commentList.value = response.data;
          res = response;
        })
        .finally(() => {
          commentListLoading.value = false;
        });
      return res;
    };

    // FORMS
    const commentLoading = ref(false);
    const handleCommentSubmit = async (data = {}) => {
      if (!data && !data.comment) return;
      commentLoading.value = true;
      data.user_id = data.user_id ?? USER.id;

      const URL = "/comments";
      const METHOD = data.id ? "PUT" : "POST";
      await api
        .custom({
          url: URL,
          method: METHOD,
          data: data,
        })
        .then(() => {})
        .finally(() => {
          commentLoading.value = false;
        });
    };

    return {
      commentList,
      commentListLoading,
      commentQuery,
      fetchCommentList,
      setCommentFetchQuery,

      // FORMS
      commentLoading,
      handleCommentSubmit,
    };
  },
  {
    persist: true,
  }
);
