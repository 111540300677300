<style scoped>
.container {
  height: 150px;
  border-radius: 26px;
  padding: 10px;
  position: relative;
}
.title {
  font-family: "SOYO Maple Bold";
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}
.img {
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
}
</style>
<template>
  <div>
    <div
      class="container"
      :style="`background : ${bgColor}; border : 1px solid ${borderColor}`"
    >
      <p class="title" v-html="title"></p>
      <v-img
        class="img"
        :height="imgHeight"
        :width="imgWidth"
        contain
        :src="img"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: "#98D5CC",
    },
    borderColor: {
      type: String,
      default: "#59DFF7",
    },
    title: {
      type: String,
      default: "Entrance of Kyoungbok Palace ",
    },
    img: {
      type: String,
      default: "chat-castle-icon.svg",
    },
    imgHeight: {
      type: String,
      default: "90",
    },
    imgWidth: {
      type: String,
      default: "150",
    },
  },
};
</script>
