<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  comments: {
    type: Array,
    default: () => {
      return [];
    },
  },
  user: {
    type: Object,
    defualt: () => {},
  },
});

const emit = defineEmits(["deletePostComment"]);
</script>

<template>
  <div
    class="comments-item mb-2"
    v-for="(comment, index) in props.comments"
    :key="index"
  >
    <div style="width: 60px">
      <v-avatar>
        <v-img
          :src="
            comment.user.image?.path ||
            require('@/assets/community-user-smile.svg')
          "
          height="40"
          width="40"
        />
      </v-avatar>
    </div>
    <span
      style="width: 70px"
      class="mr-2 d-inline-block text-truncate text-left"
    >
      {{ comment.user.name }}
    </span>
    <p class="w-100 text-left">{{ comment.comment }}</p>
    <v-menu v-if="comment.user.id === props.user.id">
      <template v-slot:activator="{ props }">
        <v-btn
          variant="text"
          icon="mdi-dots-vertical"
          size="small"
          density="comfortable"
          v-bind="props"
        >
        </v-btn>
      </template>
      <v-list density="compact" class="py-0">
        <v-list-item
          link
          density="compact"
          @click="emit('deletePostComment', comment)"
        >
          <v-list-item-title class="text-left">
            <v-icon size="small" class="mr-1">mdi-delete-outline</v-icon>
            Delete
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style scoped>
.comments-item {
  display: flex;
  align-items: center;
}
</style>
