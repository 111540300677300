import { defineStore } from "pinia";
import { ref } from "vue";

import * as api from "@/core/api";
import { useUserStore } from "./userStore";
import { useConfirmationStore } from "./confirmationStore";
export const useCommunityStore = defineStore("communityStore", () => {
  const userStore = useUserStore();
  const USER = userStore.currentUser;

  const communityList = ref([]); // generate sample commmunity data with the following data structure
  const communityListLoading = ref(false);
  const communityQuery = ref({});
  // generate sample commmunity data with the following data structure

  const setFetchQuery = (query) => {
    communityQuery.value = query;
  };

  const fetchCommunityList = async () => {
    communityListLoading.value = true;
    api
      .get("/posts", communityQuery.value)
      .then((response) => {
        communityList.value = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        communityListLoading.value = false;
      });
  };

  // CONST FORM DIALOG
  const showCommunityFormDialog = ref(false);
  const communityPostLoading = ref(false);
  const refPostDialog = ref();

  const rules = ref({
    title: [(v) => !!v || "Title is required"],
    description: [(v) => !!v || "Description is required"],
    created_by_id: [(v) => !!v || "Created By is required"],
  });

  const communityForm = ref({
    id: null,
    title: null,
    description: null,
    delete_images_ids: [],
    created_by_id: USER.id,
  });

  const openPostDialog = (data = {}) => {
    showCommunityFormDialog.value = true;
    populateCommunityForm(data);
  };

  const closePostDialog = () => {
    showCommunityFormDialog.value = false;
    populateCommunityForm();
  };

  const populateCommunityForm = (data = {}) => {
    communityForm.value.id = data.id || null;
    communityForm.value.title = data.title || null;
    communityForm.value.description = data.description || null;
    communityForm.value.created_by_id = data.created_by_id || USER.id;

    imageFiles.value = [];
    imageDisplayFiles.value = [];

    if (data.images && data.images.length > 0) {
      imageDisplayFiles.value = data.images.map((image) => {
        return {
          id: image.id,
          url: image.path,
        };
      });
    }
  };

  const postFormLoading = ref(false);
  const handlePostSubmit = async () => {
    refPostDialog.value?.validate().then(({ valid: isValid }) => {
      if (!isValid) return;
      postFormLoading.value = true;
      let URL = "/posts";

      // loading.value = true;
      const formData = new FormData();

      if (communityForm.value.id) {
        URL = `/posts/${communityForm.value.id}`;
        formData.append("_method", "PUT");
        formData.append("id", communityForm.value.id);
      }
      formData.append("title", communityForm.value.title);
      formData.append("description", communityForm.value.description);
      formData.append(
        "created_by_id",
        communityForm.value.created_by_id || USER.id
      );
      if (communityForm.value.delete_images_ids.length > 0) {
        for (var index in communityForm.value.delete_images_ids) {
          formData.append(
            "delete_images_ids[]",
            communityForm.value.delete_images_ids[index]
          );
        }
      }

      if (imageDisplayFiles.value.length > 0) {
        for (var image in imageFiles.value) {
          var file = imageFiles.value[image];
          if (file instanceof File) {
            formData.append("images[]", file);
          }
        }
      }

      api
        .custom({
          method: "POST",
          url: URL,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          closePostDialog();
          fetchCommunityList();
        })
        .finally(() => {
          postFormLoading.value = false;
        });
    });
  };
  const imageFiles = ref([]);
  const imageDisplayFiles = ref([]);
  const handleImageFiles = (event) => {
    if (event.target && event.target.files) {
      imageFiles.value = Array.from(event.target.files);
      for (var file of event.target.files) {
        var url = URL.createObjectURL(new Blob([file], { type: file.type }));
        imageDisplayFiles.value.push({ url: url });
        URL.revokeObjectURL(file); // free memory
      }
    }
  };
  const refInputFileImage = ref();
  const handleAddImge = () => {
    refInputFileImage.value.click();
  };

  const handleToDeleteImage = async (image) => {
    if (image.id) {
      const index = imageDisplayFiles.value.findIndex(
        (img) => img.id === image.id
      );
      imageDisplayFiles.value.splice(index, 1);
      await deletePostImage(image);
    } else {
      const index = imageDisplayFiles.value.findIndex(
        (img) => img.url === image.url
      );
      imageDisplayFiles.value.splice(index, 1);
    }
  };

  const deletePostImage = async (image) => {
    await api
      .custom({
        method: "POST",
        url: `/posts/${communityForm.value.id}?_method=PUT`,
        data: { id: communityForm.value.id, delete_images_ids: [image.id] },
      })
      .then(() => {
        fetchCommunityList();
      })
      .finally(() => {});
  };
  const { openConfirmationDialog, closeConfirmationDialog } =
    useConfirmationStore();
  const handleDeletePost = async (post) => {
    const warning = {
      title: `Delete Post?`,
      message: `Are you sure you want to delete ${post.title}?`,
      icon: "error",
      confirmColor: "error",
      confirmText: "Delete",
    };
    await openConfirmationDialog(warning).then((yes) => {
      if (!yes) return;
      api
        .destroy(`/posts/${post.id}`)
        .then(() => {
          fetchCommunityList();
        })
        .finally(() => {
          closeConfirmationDialog();
        });
    });
  };

  const handleDeletePostComment = async (comment) => {
    const warning = {
      title: `Delete Post Comment?`,
      message: `Are you sure you want to delete this comment?`,
      icon: "error",
      confirmColor: "error",
      confirmText: "Delete",
    };
    await openConfirmationDialog(warning).then((yes) => {
      if (!yes) return;
      api
        .destroy(`/comments/${comment.id}`)
        .then(() => {
          fetchCommunityList();
        })
        .finally(() => {
          closeConfirmationDialog();
        });
    });
  };

  return {
    communityList,
    communityListLoading,
    communityQuery,
    fetchCommunityList,
    setFetchQuery,
    handleDeletePost,
    handleDeletePostComment,

    // FORM DIALOGS
    refPostDialog,
    showCommunityFormDialog,
    communityPostLoading,
    communityForm,
    rules,
    postFormLoading,
    populateCommunityForm,
    openPostDialog,
    closePostDialog,
    handlePostSubmit,
    handleImageFiles,

    // IMAGE
    imageDisplayFiles,
    refInputFileImage,
    handleAddImge,
    handleToDeleteImage,
  };
});
