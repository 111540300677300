<script setup>
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import PostComment from "@/components/PostComment.vue";
import CommentBox from "@/components/CommentBox.vue";
import AIDefaultMessage from "@/components/Others/AIDefaultMessage.vue";

// OPENAI STORE
import { useOpenAIStore } from "@/stores/openAIStore";
const openAIStore = useOpenAIStore();
const { openai } = storeToRefs(openAIStore);
const { initializeOpenAI } = useOpenAIStore();
const chatMessages = ref([]);

onMounted( () => {
	initializeOpenAI();
})

const AIConvo = async (message) => {
	if(!openai.value) return;
  chatMessages.value.push({ message: message, isUser: true });
	scrollToBottom(50);
  const stream = await openai.value.chat.completions.create({
    model: "gpt-3.5-turbo",
    stream: true,
    messages: [
      {
        role: "user",
        content: message,
      },
    ],
  });
  var res = "";
  chatMessages.value.push({ message: res, isBot: true });
  const lastIndex = chatMessages.value.length - 1;
  for await (const chunk of stream) {
    res += chunk.choices[0]?.delta?.content || "";
    chatMessages.value[lastIndex].message = res;
    scrollToBottom();
  }
};
const scrollToBottom = (additional = 0) => {
  const chatContainer = document.querySelector(".chat-container");
  chatContainer.scrollTop = chatContainer.scrollHeight + additional;
};
</script>
<template>
  <div class="main-content-container">
    <div class="chat-container custom-scroll-bar rounded-lg">
      <CommentBox :isBot="true" class="mb-2">
        <AIDefaultMessage />
      </CommentBox>
      <div v-for="(chat, index) in chatMessages" :key="index" class="mb-2">
        <CommentBox v-if="chat.isBot" :message="chat.message" :isBot="true" />
        <CommentBox  v-if="chat.isUser" :message="chat.message" :isUser="true" />
        <br />
      </div>
    </div>
		<div class="message-box">
    <PostComment @message="AIConvo" />
		</div>
  </div>
</template>

<style scoped>
.chat-container {
  height: 65vh;
  overflow-y: auto;
  padding: 30px 10px;
	scroll-behavior: smooth;
}
.custom-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.custom-scroll-bar::-webkit-scrollbar-track {
  background-color: transparent;
}
.custom-scroll-bar::-webkit-scrollbar {
  width: 20px;
}
.main-content-container{
	position: relative;
	height: 95.5vh;
}
.message-box{
	position: absolute;
	left: 0;
	bottom: 10vh;
	width: 100%;
}
</style>