import { defineStore } from "pinia";
import * as api from "@/core/api";
import { ref } from "vue";
export const usePaymentStore = defineStore(
  "paymentStore",
  () => {
    const payment = ref(0);
    const apiResponse = ref(null);
		const paymentLoading = ref(false);
		
    const handlePayment = async (value = false) => {
      if (!value) return;
      paymentLoading.value = true;
      await api
        .post("/payments", value)
        .then((res) => {
          payment.value = res.data;
        })
        .finally(() => {
          paymentLoading.value = false;
        });
    };

    // LIST OF PAYMENTS
    const paymentList = ref([]);
    const paymentListLoading = ref(false);
    const paymentListQuery = ref({});

    const setPaymentListQuery = (query) => {
      paymentListQuery.value = query;
    };

    const fetchPaymentList = async () => {
      paymentListLoading.value = true;
      let res = null;
      await api
        .get("/payments", paymentListQuery.value)
        .then((data) => {
          paymentList.value = data.data;
          res = data;
        })
        .finally(() => {
          paymentListLoading.value = false;
        });

      return res;
    };

    return {
			payment,
      handlePayment,

      // LIST Page
      paymentList,
      apiResponse,
      paymentListLoading,
      setPaymentListQuery,
      fetchPaymentList,
    };
	},
);
