<script setup>
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";

// import SelectInterest from "@/components/Selects/SelectInterest.vue";

const router = useRouter();
const authStore = useAuthStore();
const { formSignup, rules, showPassword, showConfirmPassword, loading, validateSignup} =
  storeToRefs(authStore);

const handleSignup = () => {
  validateSignup.value?.validate().then(({ valid: isValid }) => {
		if(isValid) {
			authStore.signUp().then((response) => {
				if (response) router.push("/login");
			});
		}
  });
};
</script>

<template>
  <div class="d-flex align-center justify-center flex-column container">
    <div class="icon-container">
      <v-img width="150" height="86" src="../assets/signup-kokotalk-icon.svg" />
    </div>
    <v-form
      @submit.prevent="handleSignup"
      ref="validateSignup"
      class="form-container"
    >
      <h1 class="title mb-3">
        hello
        <div class="tilt d-inline-block">!</div>
      </h1>

      <v-text-field
        v-model="formSignup.username"
        class="mb-3"
        :rules="rules.username"
        height="50"
        color="#F7A2C1"
        base-color="#F7A2C1"
        rounded
        variant="outlined"
        hide-details=""
      >
        <template v-slot:label>
          <span class="input__label title">Username</span>
        </template>
        <template v-slot:prepend-inner>
          <v-img
            width="30"
            height="30"
            src="../assets/signup-textfield-user-icon.svg"
          />
        </template>
      </v-text-field>
      <v-text-field
        v-model="formSignup.email"
        :rules="rules.email"
        class="mb-3"
        height="50"
        color="#F7A2C1"
        base-color="#F7A2C1"
        rounded
        hide-details=""
        type="email"
        variant="outlined"
      >
        <template v-slot:label>
          <span class="input__label title">Email Address</span>
        </template>
        <template v-slot:prepend-inner>
          <v-img
            width="26"
            height="26"
            src="../assets/signup-textfield-email-icon.svg"
          />
        </template>
      </v-text-field>
      <v-text-field
        v-model="formSignup.password"
        class="mb-3"
        height="50"
        color="#F7A2C1"
        base-color="#F7A2C1"
        rounded
        :rules="rules.password"
        variant="outlined"
        hide-details=""
        :type="showPassword ? 'text' : 'password'"
        :append-inner-icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye'"
        @click:append-inner="() => (showPassword = !showPassword)"
      >
        <template v-slot:label>
          <span class="input__label title">Password</span>
        </template>
        <template v-slot:prepend-inner>
          <v-img
            width="26"
            height="26"
            src="../assets/signup-textfield-password-icon.svg"
          />
        </template>
      </v-text-field>
      <v-text-field
        v-model="formSignup.confirm_password"
        class="mb-3"
        height="50"
        color="#F7A2C1"
        base-color="#F7A2C1"
        rounded
        variant="outlined"
        hide-details=""
        :type="showConfirmPassword ? 'text' : 'password'"
        :rules="rules.confirm_password"
        @click:append-inner="() => (showConfirmPassword = !showConfirmPassword)"
        :append-inner-icon="
          showConfirmPassword ? 'mdi-eye-off-outline' : 'mdi-eye'
        "
      >
        <template v-slot:label>
          <span class="input__label title">Confirm Password</span>
        </template>
        <template v-slot:prepend-inner>
          <v-img
            width="26"
            height="26"
            src="../assets/signup-textfield-password-icon.svg"
          />
        </template>
      </v-text-field>
      <!-- <SelectInterest
        @change="formSignup.interests = $event"
        :options="{ rules: rules.interests }"
      /> -->
      <!-- <p class="hint pl-2">
        Select interests, learning objectives, and motivations.
      </p> -->
      <v-btn
        rounded
        variant="outlined"
        class="action-button mt-5 btn-register"
        type="submit"
        :loading="loading"
        >Sign up</v-btn
      >
    </v-form>
  </div>
</template>

<style scoped>
.container {
  height: 100vh;
}
.icon-container {
  height: 120px;
}
.form-container {
  background: white;
  border-radius: 24px;
  padding: 30px;
  width: 350px;
}
.title {
  color: #ff528a;
}
.tilt {
  transform: rotate(17deg);
  margin-left: -5px;
}
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(90deg);
}
.hint {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 15px;
  font-weight: 300;
  margin-top: 19px;
  text-align: left;
}
.btn-register {
  background: #ff528a;
  color: white;
}
</style>