<script setup>
import { defineProps, ref, watch } from "vue";
import { storeToRefs } from "pinia";
const props = defineProps({
  dialog: {
    type: Boolean,
    default: false,
  },
});
const show = ref(false);
watch(
  () => props.dialog,
  (val) => {
    show.value = val;
  },
  { immediate: true }
);

// AI Practice  Store
import { useAiPracticeStore } from "@/stores/aiPracticeStore";
const aiPracticeStore = useAiPracticeStore();
const { AIPracticeDetail } = storeToRefs(aiPracticeStore);
</script>
<template>
  <v-dialog persistent v-model="show" width="300">
    <v-card color="#E9FCFF">
      <v-card-title class="text-left align-center justify-space-between d-flex">
        <div class="d-flex align-center">
          <v-icon color="#49EE16" size="24" class="mr-2"
            >mdi-check-circle</v-icon
          >
          <p>Mission</p>
        </div>
        <v-btn icon size="26" variant="text">
          <template v-slot:default>
            <v-icon size="24" @click="$emit('dialog', false)">mdi-close</v-icon>
          </template>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="text-left"
        v-if="AIPracticeDetail && AIPracticeDetail.missions"
      >
        <div
          :class="`${
            index != AIPracticeDetail.missions.length - 1 ? 'mb-2' : ''
          } text-left d-flex align-center mb-4`"
          v-for="(mission, index) in AIPracticeDetail.missions"
          :key="index"
        >
          <v-icon size="30" :color="mission.is_completed ? '#49EE16' : '#9E9E9E' " class="mr-2"> mdi-check-circle-outline </v-icon>
          <p class="text-mission">
            <b> {{ mission.title_ko }} </b><br />
            <small> {{ mission.title_en }} </small>
          </p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.text-mission{
	line-height: 14px;
}
</style>