<script setup>
import { defineProps, onMounted, ref } from "vue";
import { useBookmarkStore } from "@/stores/bookmarkStore";
const { handleBookark, handleDeleteBookmark } = useBookmarkStore();

const props = defineProps({
  model: {
    type: String,
    default: "posts",
  },
  model_id: {
    type: Number,
    default: null,
  },
  id: {
    type: Number,
    default: null,
  },
});

const bookmark_id = ref(null);
const loadingBookmark = ref(false);

onMounted(() => {
  bookmark_id.value = props.id;
});

const handleBookmark = async () => {
  loadingBookmark.value = true;
  if (bookmark_id.value)
    await handleDeleteBookmark(bookmark_id.value).then(() => {
      bookmark_id.value = null;
    });
  else
    await handleBookark(props.model, props.model_id).then((res) => {
      bookmark_id.value = res.data.id;
    });

  loadingBookmark.value = false;
};
</script>

<template>
  <v-btn
    @click="handleBookmark"
    :loading="loadingBookmark"
    icon="true"
    variant="icon"
    size="24"
    class="mr-2 elevation-0"
  >
    <v-icon :color="bookmark_id ? '#FF6666' : 'black'">mdi-bookmark</v-icon>
  </v-btn>
</template>
